<template>
    <div class="form-group" :id="wrapper_question_id" :class="class_object_wrapper">

        <label :for="question_id" v-if="question.place_holder_text != '' && this.question.label != ''">{{ question.label }}</label>

        <div>
          <label for="cardNumber"></label>
          <input type="text" pattern="[0-9.]*" id="cardNumber" class="form-control fl-input" placeholder="Card number" v-model="cardNumber" :class="classObject('cardNumber')">
        </div>

        <div class="mt-2">
          <label for="cardExpiration">Expiration date (MM/YY)</label>
          <input type="text" id="cardExpiration" pattern="[0-9./]*" v-mask="'##/##'" class="form-control fl-input" placeholder="Expiration date (MM/YY)" v-model="cardExpiration" :class="classObject('cardExpiration')">
        </div>

        <div class="mt-2">
          <label for="cardCode"></label>
          <input type="text" id="cardCode" pattern="[0-9.]*" class="form-control fl-input" placeholder="CVV" v-model="cardCode" :class="classObject('cardCode')">
        </div>

        <span :id="aria_desc" class="form-text small" v-if="question.description">{{ question.description }}</span>
        <div v-show="feedback_text!=''" class="alert alert-danger mt-2" v-html="feedback_text"></div>
    </div>
</template>

<script type="text/javascript">
    import { mask } from 'vue-the-mask'
    export default {
        directives: { mask },
        props: ['question','ccProcessingData'],
        data(){
            return {
                cardNumber: '',
                cardCode: '',
                cardExpiration: '',
                feedback_text: ''
            }
        },
        created(){
            // Installing the authorize.net script
            let authorizeNetScriptUrl = process.env.VUE_APP_ENVIRONMENT === 'production' ? 'https://js.authorize.net/v1/Accept.js' : 'https://jstest.authorize.net/v1/Accept.js';
            let authorizeNetScript = document.createElement('script');
            authorizeNetScript.setAttribute('src', authorizeNetScriptUrl);
            document.head.appendChild(authorizeNetScript);
            this.$eventHub.$on('submitCreditCardData', () => {
                this.sendPaymentDataToAnet();
            });
        },
        computed: {
            cardMonth(){
              let date = this.cardExpiration.split('/');
              return date[0];
            },
            cardYear(){
              let date = this.cardExpiration.split('/');
              return date[1];
            },
            placeholder(){
                return this.question.label!='' ? this.question.label : this.question.place_holder_text!='';
            },
            wrapper_question_id(){
                return 'question_'+this.question.name;
            },
            question_id(){
                return "text_field_" + this.question.step + this.question.sort;
            },
            aria_desc(){
                return "desc_text_" + this.question.step + this.question.sort;
            },
            class_object_wrapper(){
                return {
                    'fl-is-active': this.value!='', // this makes the select input change to black text when autocomplete is triggered
                }
            },
            step(){
                return this.$store.state.step;
            }
        },
        methods: {
            classObject(field){
                if (field==='cardNumber') {
                    return {
                        'is-invalid': this.feedback_text.indexOf('credit card number') >= 0
                    }
                }
                if (field==='cardExpiration') {
                    return {
                        'is-invalid': this.feedback_text.indexOf('year') >= 0 || this.feedback_text.indexOf('month') >= 0
                    }
                }
                if (field==='cardCode') {
                    return {
                        'is-invalid': this.feedback_text.indexOf('code') >= 0
                    }
                }
            },
            sendPaymentDataToAnet() {
                // console.log("sendPaymentDataToAnet()");
                let authData = {
                    clientKey: this.ccProcessingData.clientKey,
                    apiLoginID: this.ccProcessingData.apiLoginID
                }
                let cardData = {
                    cardNumber: this.cardNumber,
                    month: this.cardMonth,
                    year: this.cardYear,
                    cardCode: this.cardCode
                }
                let secureData = { authData, cardData };
                // console.log(secureData);
                Accept.dispatchData(secureData, this.anetResponseHandler);
            },
            anetResponseHandler(response) {
                console.log("anetResponseHandler()",response);

                // console.trace();
                let errors = [];
                if (response.messages.resultCode === "Error") {
                    for (let i = 0; i < response.messages.message.length; i++) {
                        //errors += "\n" + response.messages.message[i].code + ": " + response.messages.message[i].text;
                        errors.push(response.messages.message[i].text);
                    }
                    this.feedback_text = errors.join('<br/>');
                    // console.log(this.feedback_text);
                    this.$emit('creditCardError');
                    return;
                }

                let anetValueQuestion = this.$store.state.questions.find( question => question.name === 'anetValue');
                anetValueQuestion.value = response.opaqueData.dataValue;
                this.$store.commit('update_question', anetValueQuestion);

                let anetDescriptorQuestion = this.$store.state.questions.find( question => question.name === 'anetDescriptor');
                anetDescriptorQuestion.value = response.opaqueData.dataDescriptor;
                this.$store.commit('update_question', anetDescriptorQuestion);

                this.$emit('creditCardProcessed');
                this.feedback_text = '';
            }
        }
    }
</script>