<template>
    <div id="app">
        <Autofiller v-if="showAutofiller"/>
        <div class="page-background"></div>
        <ProgressBar :app_error="app_error"/>
        <main role="main" id="sep_main">
            <div class="container-fluid">
                <div class="d-flex justify-content-center">
                    <div class="section-card">
                        <div class="card card-sep rounded-lg shadow-lg border-0 bg-white">
                            <div class="card-body">
                                <AppError v-if="app_error" />
                                <Main v-else />
                            </div>
                            <div class="card-footer d-flex justify-content-between align-items-center bg-primary text-white bg-card-footer" v-if="parseFloat(step)>=20">
                                <span data-contentful="body:need_help">
                                    {{ $t('body:need_help', 'Need help?  Visit ') }} <a target="_blank" rel="noopener nofererer" href="https://support.wondrhealth.com" class="text-white text-underline">support.wondrhealth.com</a>
                                </span>
                                <a class="footer-logout" href="/a/KDpg_logout.html" data-contentful="body:save_exit">
                                    {{ $t('body:save_exit', 'Save & Exit') }}
                                </a>
                            </div>
                            <div class="card-footer text-center bg-primary text-white bg-card-footer" v-else>
                                <span data-contentful="body:need_help">
                                    {{ $t('body:need_help', 'Need help?  Visit ') }} <a target="_blank" rel="noopener nofererer" href="https://support.wondrhealth.com" class="text-white text-underline">support.wondrhealth.com</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <footer role="contentinfo" id="sep_footer" class="text-white pt-4">
            <div class="container-fluid">
                <div class="row align-items-center mx-5 mx-lg-5 mx-xl-5">
                    <div class="col-lg-6 text-center text-lg-left legal">
                        <p data-contentful="footer:rights_information">
                            {{
                                $t(
                                    'footer:rights_information',
                                    '© :year Wondr Health. All Rights Reserved.',
                                    {
                                        replace: {
                                          ':year': new Date().getFullYear(),
                                        },
                                    }
                                )
                            }}
                        </p>
                    </div>
                    <div class="col-lg-6 text-center text-lg-right legal">
                        <p>
                            <a href="https://wondrhealth.com/privacy" target="_blank" rel="noreferer noopener" data-contentful="footer:privacy">
                                {{ $t('footer:privacy', 'Privacy') }}
                            </a> | <a href="https://wondrhealth.com/terms-of-use" target="_blank" rel="noreferer noopener" data-contentful="footer:terms">
                                {{ $t('footer:terms', 'Terms') }}
                            </a><br>
                            <a :href="`${lms_url}/v5.0/documents/wondr-non-discrimination-statement.pdf`" target="_blank" data-contentful="footer:non-discrimination">
                                {{ $t('footer:non-discrimination', 'Non-Discrimination Statement') }}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        <PopupModal />
        <WaitlistModal />
    </div>
</template>

<script type="text/javascript">
import axios from 'axios';
import Autofiller from '@/components/Autofiller.vue';
import AppError from '@/components/AppError.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import PopupModal from '@/components/PopupModal.vue';
import WaitlistModal from '@/components/modals/WaitlistModal.vue';
import Main from '@/views/Main.vue';
import ContentfulMixin from '@/mixins/contentful';
import mixins from '@/mixins';
import {createEnrollmentStartPayload, sendEventToEventBridge} from "./helpers/aws-event-bridge";
export default {
    mixins: [mixins, ContentfulMixin],
    components: {
        Autofiller,
        ProgressBar,
        PopupModal,
        WaitlistModal,
        AppError,
        Main
    },
    watch: {
        '$store.state.featureFlags': 'handleFeatureFlagChange',
        '$store.state.locale': 'handleLocaleChange',
        step(val){
            if (val){
                this.$nextTick(() => {
                    dataLayer.push({'event': 'optimize.step.' + val});
                });
            }
        },
        user_id(val){
            if (val > 0){
                // console.log(`pushing dataLayer.user_id: ${val}`)
                dataLayer.push({'user_id': val});
                this.sendEnrollmentStartEvent();
            }
        },
        class_id(val){
            if (val > 0){
                // console.log(`pushing dataLayer.class_id: ${val}`)
                dataLayer.push({'class_id': val});
            }
        }
    },
    computed: {
        showAutofiller(){
            let isProdUrl = window.location.href.indexOf("//enroll.wondrhealth.com/") >= 0;
            const isProdEnv = process.env.VUE_APP_ENVIRONMENT === 'production';
            return this.step>=20 && !isProdEnv && !isProdUrl;
        },
        lms_url(){
            return VUE_APP_LMS;
        },
        step(){
            return this.$store.state.step;
        },
        app_error(){
            return this.$store.state.app_error;
        },
        user_id(){
            return this.$store.state.user_id;
        },
        class_id(){
            return this.$store.state.class_id;
        },
        enrollment_closed_error(){
          return this.$store.state.errorMessage === "Bad Request. Enrollment is closed."
        },
        jwt_token(){
            return this.$store.state.jwt_token;
        },
    },
    created () {
        const locale = this.getLocaleValue();
        this.$store.commit('update_locale', locale);
        this.setLocaleCookie(locale);
        const urlHash = this.getUrlHash();
        this.$store.dispatch('initializeApp', { locale, hash: urlHash }).then( r => {
            if (this.$store.state.show_popup_modal && (this.$store.state.step==='10') || (r.Demo_Mode)){
                jQuery('#popup_modal').modal('toggle');
            }
            this.$eventHub.$emit('appInitialized',r);
            // NS3087 this is necessary to activate google optimize on SPAs
            dataLayer.push({'event': 'optimize.activate'});
            this.$store.commit('update_locale', locale)
        });
        this.checkSoleraClient();
        // heartbeat
        this.heartbeat();
    },
    methods: {
        heartbeat(){
            // console.log('Heartbeat sent');
            axios.get(this.$store.state.endpoints.heartbeat);
            setTimeout( () => {
                this.heartbeat();
            }, 300000); // every 5 minutes
        },
        handleFeatureFlagChange(newFeatureFlags, oldFeatureFlags) {
            const isSpanishLocalizationFlagChanged =
                newFeatureFlags['enable-spanish-localization'] !== oldFeatureFlags['enable-spanish-localization'];
            if (isSpanishLocalizationFlagChanged) {
                this.handleLocaleChange();
            }
        },
        handleLocaleChange() {
            if (this.enableSpanishLocalization()) {
                this.getContentfulEntries();
            }
        },
        isSoleraUser(){
            const urlParams = new URLSearchParams(window.location.search);
            const clientName = urlParams.get('s');
            const lookupKey = urlParams.get('lookupKey');
            return clientName==='solera' && lookupKey;
        },
        checkSoleraClient() {
            if(this.isSoleraUser()){
                const urlParams = new URLSearchParams(window.location.search);
                const lookupKey = urlParams.get('lookupKey');
                this.$store.dispatch('checkIntegrationService', lookupKey);
            }
        },
        sendEnrollmentStartEvent(){
            let payload = createEnrollmentStartPayload();
            if(this.isSoleraUser()){
                const urlParams = new URLSearchParams(window.location.search);
                const lookupKey = urlParams.get('lookupKey');
                payload.lookup_key = lookupKey;

            }
            sendEventToEventBridge('enrollment-start', payload, this.jwt_token);
        },
    },
}
</script>
