import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import store from './store'

import { ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import './plugins/contentful'
extend('email', {
  ...email,
  message: 'Email address is not valid'
});
extend('required', {
  ...required,
  message: 'This field is required'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue();

new Vue({
  // router,
  store,
  render: h => h(App)
}).$mount('#app')
