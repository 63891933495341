var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"modal_corp_landing_waitlist","tabindex":"-1","role":"dialog","aria-label":"Join the Waitlist","aria-modal":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header justify-content-center"},[_c('h2',{staticClass:"modal-title",attrs:{"data-contentful":"modal:waitlist:title"}},[_vm._v(" "+_vm._s(_vm.$t('modal:waitlist:title', 'Join the Waitlist'))+" ")]),_c('button',{staticClass:"btn btn-white shadow btn-close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"d-flex justify-content-center mb-4 text-center"},[_c('div',{staticStyle:{"width":"100%","max-width":"400px"}},[_c('p',{attrs:{"data-contentful":"modal:waitlist:title_above"}},[_vm._v(" "+_vm._s(_vm.$t('modal:waitlist:title_above', "We'll notify you when the next application period opens."))+" ")])])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.completed),expression:"!completed"}],staticClass:"js-waitlist-form form-floating-labels",attrs:{"method":"post","novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex justify-content-center card-form"},[_c('div',{staticStyle:{"width":"100%","max-width":"300px"}},[_c('div',{staticClass:"form-group"},[_c('div',[_c('label',{attrs:{"for":"first_name"}},[_vm._v(_vm._s(_vm.text_first_name))]),_c('validation-provider',{staticClass:"form-text small text-danger",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","id":"first_name","name":"first_name","placeholder":_vm.text_first_name,"aria-label":_vm.text_first_name,"data-placeholder":_vm.text_first_name,"data-contentful":"modal:waitlist:first_name"},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('div',[_c('label',{attrs:{"for":"last_name"}},[_vm._v(_vm._s(_vm.text_last_name))]),_c('validation-provider',{staticClass:"form-text small text-danger",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","id":"last_name","name":"last_name","placeholder":_vm.text_last_name,"aria-label":_vm.text_last_name,"required":"","data-placeholder":_vm.text_last_name,"data-contentful":"modal:waitlist:last_name"},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('div',[_c('label',{attrs:{"for":"user_email"}},[_vm._v(_vm._s(_vm.text_email_address))]),_c('validation-provider',{staticClass:"form-text small text-danger",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_email),expression:"user_email"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","id":"user_email","name":"user_email","placeholder":_vm.text_email_address,"aria-label":_vm.text_email_address,"required":"","data-placeholder":_vm.text_email_address,"data-contentful":"modal:waitlist:email_address"},domProps:{"value":(_vm.user_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.user_email=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('input',{attrs:{"type":"hidden","name":"C_Corporate_Clients_item_id","id":"C_Corporate_Clients_item_id","value":"' . (int)$corpClientRow['item_id'] . '"}}),_c('input',{attrs:{"type":"hidden","name":"code2","id":"code2","value":"' .$code2. '"}}),_c('p',[_c('button',{staticClass:"btn btn-block shadow text-spacing-4 btn-primary-light",attrs:{"disabled":invalid || _vm.loading,"type":"submit","data-contentful":"body:button_join_waitlist"},domProps:{"innerHTML":_vm._s(_vm.submit_button_content)}})]),_c('p',[_c('button',{staticClass:"btn btn-block btn-no-shadow text-spacing-4 btn-white",attrs:{"type":"button","data-dismiss":"modal","data-contentful":"body:button_cancel"}},[_vm._v(" "+_vm._s(_vm.$t('body:button_cancel', 'Cancel'))+" ")])])])])])]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.completed),expression:"completed"}]},[_c('div',{staticClass:"d-flex justify-content-center card-form"},[_c('div',{staticStyle:{"width":"100%","max-width":"300px"}},[_c('p',[_c('button',{staticClass:"btn btn-block btn-no-shadow text-spacing-4 btn-white",attrs:{"type":"button","data-dismiss":"modal","data-contentful":"body:button_dismiss"}},[_vm._v(" "+_vm._s(_vm.$t('body:button_dismiss', 'Dismiss'))+" ")])])])])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }