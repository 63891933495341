<template>
    <div>
        <div class="d-flex justify-content-center card-intro text-center">
            <div style="width: 100%; max-width: 470px;" v-if="enrollment_completed_error">
                <h1 class="text-32 text-center" data-contentful="body:completed_enrollment_title">
                    {{ $t('body:completed_enrollment_title', 'You have already completed your enrollment.') }}
                </h1>
                <div v-html="enrollment_completed_error_message"></div>
            </div>
            <div style="width: 100%; max-width: 470px;" v-else-if="enrollment_closed_error">
                <h1 class="text-32 text-center" data-contentful="body:enrollment_closed_title">
                    {{ $t('body:enrollment_closed_title', 'The enrollment for this class is now closed.') }}
                </h1>
                <p data-contentful="body:reach_out">
                    {{ $t('body:reach_out', 'If you have further questions, you can reach out via ') }}<a href="mailto:support@wondrhealth.com">support@wondrhealth.com</a>
                </p>
                <a
                    href="#modal_corp_landing_waitlist"
                    role="button"
                    class="btn btn-sm rounded-xs btn-primary-light btn-enroll ml-auto"
                    data-toggle="modal"
                    data-target="#modal_corp_landing_waitlist"
                    data-contentful="body:join_waitlist"
                >
                    {{ $t('body:join_waitlist', 'Join the Waitlist') }}
                </a>
            </div>
            <div style="width: 100%; max-width: 470px;" v-else>
                <h1 class="text-32 text-center" data-contentful="body:something_wrong_title">
                    {{ $t('body:something_wrong_title', 'It looks like something went wrong') }}
                </h1>
                <p data-contentful="body:something_wrong_instructions" v-html="something_wrong_instructions_content"/>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        computed: {
            error_message(){
              return this.$store.state.errorMessage;
            },
            enrollment_completed_error(){
                return this.error_message.startsWith("Completed: ")
            },
            enrollment_closed_error(){
                return this.error_message === "Bad Request. Enrollment is closed."
            },
            enrollment_completed_error_message(){
                return this.error_message ? this.error_message.replace("Completed: ","") : ''
            },
            something_wrong_instructions_content(){
              return this.$t(
                  'body:something_wrong_instructions',
                  'You can try going back to the <a href="https://wondrhealth.com">homepage</a> or contact <a href="mailto:support@wondrhealth.com">support@wondrhealth.com</a>');
            },
        },
    }
</script>