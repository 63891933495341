// All possible misspells from users
let com = ['.com.com','.con','.dom','.vom','.xom','.cim','.clm','.cpm','.cok','.coj'];
let net = ['.met','.het','.bet','.ner','.neg','.ney'];
let gov = ['.rov','.nov','.tov','.hov','.giv','.glv','.gpv','.goc','.gob','.gof'];
let org = ['.irg','.lrg','.prg','.oeg','.otg','.ogg','.ofg','.orr','.orn','.ort','.orh'];
let edu = ['.deu','.eeu','.esu','.ecu','.efu','.edi','.edy','.edj'];
let gmail = ['@gmial.com','@gamil.com','@rmail.com','@nmail.com','@tmail.com','@hmail.com','@gnail.com','@gkail.com','@gjail.com','@gmsil.com','@gmzil.com','@gmqil.com','@gmaol.com','@gmaul.com','@gmakl.com','@gmaip.com','@gmaim.com','@gmaik.com'];
let outlook = ['@uotlook.com','@outlok.com','@iutlook.com','@lutlook.com','@putlook.com','@oitlook.com','@oytlook.com','@ojtlook.com','@ourlook.com','@ouglook.com','@ouylook.com','@outpook.com','@outmook.com','@outkook.com','@outliok.com','@outllok.com','@outlpok.com','@outloik.com','@outlolk.com','@outlopk.com','@outlooi.com','@outlooo.com','@outlool.com','@outloom.com'];
let yahoo = ['@yhaoo.com','@yaho.com','@tahoo.com','@uahoo.com','@hahoo.com','@yshoo.com','@yzhoo.com','@yqhoo.com','@yanoo.com','@yatoo.com','@yagoo.com','@yaboo.com','@yahio.com','@yahlo.com','@yahpo.com','@yahoi.com','@yahol.com','@yahop.com','@yhoo.com'];
let icloud = ['@icluod.com','@ocloud.com','@ucloud.com','@kcloud.com','@idloud.com','@ivloud.com','@ixloud.com','@icpoud.com','@icmoud.com','@ickoud.com','@icliud.com','@icllud.com','@iclpud.com','@icloid.com','@icloyd.com','@iclojd.com','@icloue.com','@iclous.com','@iclouc.com','@iclouf.com'];
let hotmail = ['@hotnail.com','@hotmial.com','@hotmall.com','@hotmal.com','@hotmil.com','@homail.com'];

function email_suggestions(email) {

    let suggest = [];

    // com
    suggest = com.filter( x => email.endsWith(x)).map( x => email.replace(x,".com"));
    if (suggest.length) return suggest[0];

    // net
    suggest = net.filter( x => email.endsWith(x)).map( x => email.replace(x,".net"));
    if (suggest.length) return suggest[0];

    // gov
    suggest = gov.filter( x => email.endsWith(x)).map( x => email.replace(x,".gov"));
    if (suggest.length) return suggest[0];

    // org
    suggest = org.filter( x => email.endsWith(x)).map( x => email.replace(x,".org"));
    if (suggest.length) return suggest[0];

    // edu
    suggest = edu.filter( x => email.endsWith(x)).map( x => email.replace(x,".edu"));
    if (suggest.length) return suggest[0];

    // gmail
    suggest = gmail.filter( x => email.endsWith(x)).map( x => email.replace(x,"@gmail.com"));
    if (suggest.length) return suggest[0];

    // outlook
    suggest = outlook.filter( x => email.endsWith(x)).map( x => email.replace(x,"@outlook.com"));
    if (suggest.length) return suggest[0];

    // yahoo
    suggest = yahoo.filter( x => email.endsWith(x)).map( x => email.replace(x,"@yahoo.com"));
    if (suggest.length) return suggest[0];

    // icloud
    suggest = icloud.filter( x => email.endsWith(x)).map( x => email.replace(x,"@icloud.com"));
    if (suggest.length) return suggest[0];

    // hotmail
    suggest = hotmail.filter( x => email.endsWith(x)).map( x => email.replace(x,"@hotmail.com"));
    if (suggest.length) return suggest[0];

    return false;
}

export default email_suggestions;