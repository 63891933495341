import axios from 'axios';
import moment from 'moment';
import { lambdaAuthenticatorUrl, environment } from "../config";
import store from '../store'

export function createEnrollmentStartPayload(){
    let payload = {
        user_id: parseInt(store.state.user_id),
        group_membership_id: parseInt(store.state.class_id),
        enrollment_data_id: parseInt(store.state.enrollment_data_id),
        created_at: moment().utc().toISOString(),
        shortcut_URL: store.state.shortcut_URL,
        is_cys: store.state.is_cys,
        corporate_clients_id: parseInt(store.state.corp_client_id),
        billing_type: store.state.billing_type,
        user_type: store.state.user_type,
        user_email: store.state.saved_email,
        enrollment_status: 'Incomplete',
        environment: environment,
    }
    if(store.getters.lookup_key){
        payload.lookup_key = store.getters.lookup_key;
    }
    return payload;
}

export function formatResponseFields(response){
    let requiredFields = {
        user_id: parseInt(response.dialogs_user_id),
        group_membership_id: parseInt(response.class_id),
        enrollment_data_id: parseInt(response.enrollment_data_id),
        created_at: moment().utc().toISOString(),
        shortcut_URL: response.shortcut_URL,
        is_cys: response.is_cys,
        corporate_clients_id: parseInt(response.corp_client_id),
        billing_type: response.billing_type,
        user_type: response.userType,
        user_email: response.user_email,
        enrollment_status: response.enrollment_status,
        environment: environment,
    };
    return requiredFields;
}

export function formatQuestionsArray(payload){
    let questions = [];
    for (const key in payload) {
        if(key !== 'step' && key !== 'action'){
            questions.push({
                name: key,
                value: payload[key]
            })
        }
    }
    return questions;
}

export async function sendEventToEventBridge(eventName, eventDetails, jwt) {

    try {
        const event = {
            "Source": "wondr/web",
            "Time": parseInt(moment().utc().format('X')),
            "EventBusName": "enrollment-bus",
            "DetailType": eventName,
            "Detail": JSON.stringify(eventDetails)
        };
        const data = await axios.post(
            lambdaAuthenticatorUrl,
            event,
            {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            }
        );
        return data;
    } catch (err) {
        console.log("Error", err);
    }
}
