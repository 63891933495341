<script>
import BlockCard from '@/components/form-bloks/BlockCard.vue';

export default {
  props: {
    step: {
      type: [String, Number],
      required: true,
    },
    childProps: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h) {
    return this.childProps
      ? h(BlockCard, {
        props: {
          ...this.childProps,
          specialImageClass: this.step.toString().startsWith('10') ? 'special-block-image' : '',
        },
      })
      : null;
  },
};
</script>

<style>
.step-block-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #1A1919;
  margin-bottom: 7px;
}
.step-block-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1A1919CC;
}
.step-block-description strong {
  font-weight: 700;
  color: #1A1919;
}
.block-image {
  width: 130px;
  height: 130px;
  overflow: hidden;
  margin-right: 16px;
}
.block-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .step-block-title {
    font-size: 14px;
    line-height: 20px;
  }
  .step-block-description {
    font-size: 12px;
    line-height: 18px;
  }
  .block-image {
    margin-right: 0;
    margin-bottom: 16px;
  }
  .special-block-image {
    margin-bottom: 16px;
  }
}
</style>
