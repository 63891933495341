import Vue from 'vue'
import { mapState } from 'vuex'
import mixins from '@/mixins';
import { createClient }  from 'contentful'

const client = createClient({
    space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.VUE_APP_CONTENTFUL_TOKEN,
    environment: process.env.VUE_APP_CONTENTFUL_ENVIRONMENT,
})

const Plugin = {
    install(Vue) {
        Vue.prototype.$contentful = client
    },
}

Vue.mixin({
    mixins: [mixins],
    computed: {
        ...mapState(['contentfulContent']),
    },

    methods: {
        $t(identifier= '', byDefault= '', options = {}) {
            const corporateClientsShortcutURL = this.getUrlParam('s').toLowerCase();
            const urlHash = this.getUrlHash();
            identifier = identifier.toLowerCase()
            const entries = this.contentfulContent.entries;
            let contentFound = byDefault
            if (entries) {
                const identifiers = [
                    `${corporateClientsShortcutURL}:${urlHash}:${identifier}`,
                    `${corporateClientsShortcutURL}:${identifier}`,
                    `${urlHash}:${identifier}`,
                    identifier,
                ];

                for (const id of identifiers) {
                    if (entries[id]) {
                        contentFound = entries[id].label || byDefault;
                        break;
                    }
                }
            }

            if (options.replace) {
                for (const [ key, value ] of Object.entries(options.replace)) {
                    contentFound = contentFound.replace(key, value)
                }
            }

            return contentFound
        },
    }
})

Vue.use(Plugin, {})