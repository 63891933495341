<template>
    <div class="form-group" :id="wrapper_question_id" :class="class_object_wrapper" v-show="show_question" :data-contentful="this.question.name">
        <label :for="question_id" class="fl-input-lg">{{question.label}}</label>
        <select :value="question.value" class="custom-select custom-select-lg fl-input" @change="inputChanged" :class="class_object" :id="question_id" ref="selectField">
            <option value="" selected disabled>{{question.label}}</option>
            <option v-for="(option,index) in options" :key="index" :value="option.value">{{option.label}}</option>
        </select>
        <div :id="aria_desc" class="form-text small" v-if="question.description">{{question.description}}</div>
        <div class="form-text small text-danger" v-if="has_error" v-html="question.validation_message"></div>
    </div>
</template>

<script type="text/javascript">
    export default {
        props: ['question','hold'],
        data(){
            return {
                value: '',
                has_error: false,
                show_question: false
            }
        },
        created(){
            this.$eventHub.$on('help_me', () => {
                let value = this.options[0].value;
                if (this.question.name=="height_feet"){
                    value = 5;
                } else if (this.question.name=="height_inches"){
                    value = 11;
                }
                this.value = value;
                this.question.value = value;
                this.$emit('inputChanged',this.question);
            });
            this.$eventHub.$on('question_has_error',({name,step})=>{
                if ((this.question.name===name)&&(this.question.step===step)){
                    this.has_error = true;
                }
            });
            this.$eventHub.$on('reset_fields',()=>{
                this.value = this.question.value;
                this.question.hold_value = this.question.value;
                if (this.$refs.selectField){
                    if (this.question.value.length>0){
                        jQuery(this.$refs.selectField).parent().addClass("fl-is-active");
                    }
                }
            });
            this.$eventHub.$on('update_value',(payload) => {
                if (this.question.name==payload.name){
                    this.value = payload.value;
                }
                this.$store.commit('update_value', payload);
            });
            this.$eventHub.$on('questions_installed', ()=>{
                this.value = this.question.value;
            });
            this.$eventHub.$on('question_updated',(q)=>{
                // console.log(this.question.visible_if_question_name)
                // console.log(q.name)
                // console.log(this.question.visible_if_field_value)
                // console.log(q.value)
                if ((this.question.visible_if_question_name!="") && (this.question.visible_if_field_value!="")){
                    if ((q.name==this.question.visible_if_question_name)&&(q.value==this.question.visible_if_field_value)){
                        this.show_question = true;
                    } else if ((q.name==this.question.visible_if_question_name)&&(q.value!=this.question.visible_if_field_value)) {
                        this.show_question = false;
                    }
                }
            });
        },
        mounted(){
            if (this.question.visible_if_question_name=="0"){
                this.show_question = true;
            }
        },
        computed: {
            wrapper_question_id(){
                return 'question_'+this.question.name;
            },
            options(){
                return this.question.options.split('\n').map(x => {
                    let temp = x.split('|');
                    return {
                        value: temp[1],
                        label: temp[0]                        
                    }
                });
            },
            question_id(){
                return "ref_"+this.question.name;
            },
            class_object(){
                return {
                    'is-invalid': this.has_error,
                    'is-active': this.value!='' // this makes the select input change to black text when autocomplete is triggered
                }
            },
            class_object_wrapper(){
                return {
                    'fl-is-active': this.value!='' // this makes the select input change to black text when autocomplete is triggered
                }
            }
        },
        methods: {
            inputChanged(e){
                this.has_error = false;
                if ( !this.question.hold_value ) { // save original value in hold_value
                    this.question.hold_value = this.value;
                }
                this.has_error = false;
                let val = e.target.value;
                this.question.value = val;
                this.$emit('inputChanged',this.question);
            },
        }
    }
</script>