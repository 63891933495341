<template>
  <div>
      <div class="d-flex justify-content-center card-intro text-center">
          <div class="" style="width: 100%; max-width: 470px;">
              <h1 class="text-32 text-center" data-contentful="body:title">
                  {{ $t('body:title', 'Login to re-enroll') }}
              </h1>
              <p data-contentful="body:existing_account" v-html="existing_account_content"></p>
          </div>
      </div>
      <form novalidate="novalidate" class="form-floating-labels" @submit.stop.prevent="signIn">
          <div class="d-flex justify-content-center card-form">
              <div style="width: 100%; max-width: 300px;">
                  <div class="form-group centered-input" style="margin-bottom: 36px;" v-show="multiple_accounts">
                      <div>
                          <label for="username">{{ text_username_address }}</label>
                          <input
                              type="text"
                              id="username"
                              :placeholder="text_username_address"
                              :aria-describedby="text_username_address"
                              tabindex="1"
                              class="form-control form-control-lg text-center"
                              autocomplete="off"
                              :data-placeholder="text_username_address"
                              :class="{ 'is-invalid' : !validation.username, 'fl-input': floatlabels !== null }"
                              ref="username_field"
                              @keydown="fieldUpdated('username')"
                              v-model='username'
                              data-contentful="user_name"
                          >
                      </div>
                      <p class="mt-2 mb-0 small">
                          <a target="_blank" href="/Forgot_UserID.html" class="text-gray-dark" tabindex="-1" data-contentful="body:forgot_username">
                              {{ $t('body:forgot_username', 'Forgot username') }}
                          </a>
                      </p>
                  </div>
                  <div class="form-group centered-input" style="margin-bottom: 36px;">
                      <div>
                          <label for="text_3">{{ text_password_address }}</label>
                          <input
                              id="text_3"
                              tabindex="2"
                              class="form-control form-control-lg text-center"
                              autocomplete="off"
                              :placeholder="text_password_address"
                              :data-placeholder="text_password_address"
                              ref="password_field"
                              :type="show_password ? 'text' : 'password'"
                              @keyup="checkCaps"
                              @keydown="fieldUpdated('password')"
                              :class="{ 'is-invalid' : !validation.password, 'fl-input': floatlabels !== null }"
                              v-model="password"
                              data-contentful="password"
                          >
                      </div>
                      <div class="form-text small text-gray" v-if="caps_lock_enabled!=''" data-contentful="body:caps_lock">
                          {{ $t('body:caps_lock', 'Caps lock is enabled') }}
                      </div>
                      <div class="form-text small text-danger" v-if="unauthorized_error" data-contentful="body:password_recovery_instructions">
                          <strong data-contentful="body:invalid_information">{{ $t('body:invalid_information', 'Invalid username or password') }}</strong>{{ $t('body:password_recovery_instructions', '. You can use the Forgot Password and Recover Username feature below.') }}
                      </div>
                      <p class="mt-2 mb-0 small d-flex justify-content-between">
                          <a target="_blank" href="/Reset_Password.html" class="text-gray-dark" tabindex="-1" data-contentful="body:forgot_password">
                              {{ $t('body:forgot_password', 'Forgot password') }}
                          </a>
                          <button type="button" id="show_password" class="btn btn-xs btn-link p-0" aria-label="View Password" @click.stop.prevent="togglePassword">
                              <svg class="icon-18 icon-gray-dark" v-if="show_password"><use xlink:href="#view"/></svg>
                              <svg class="icon-18 icon-gray-dark" v-else><use xlink:href="#viewno"/></svg>
                          </button>
                      </p>
                  </div>
                  <p>
                      <button type="submit" class="btn btn-primary-light shadow btn-block" v-html="next_button_content" data-contentful="body:button_next"></button>
                  </p>
                  <p class="mt-4">
                      <button
                          type="button"
                          class="btn btn-block btn-no-shadow text-uppercase text-spacing-4 btn-white"
                          @click="changeStep(10)"
                          data-contentful="body:button_previous"
                      >
                          {{ $t('body:button_previous', 'Previous') }}
                      </button>
                  </p>
              </div>
          </div>
      </form>
  </div>
</template>

<script>
import mixins from '@/mixins';
export default {
  mixins: [mixins],
  data(){
    return {
      username: '',
      password: '',
      validation: {
        username: true,
        password: true,
      },
      floatlabels: null,
      show_password: false,
      caps_lock_enabled: false,
      unauthorized_error: false,
      loading: false,
    }
  },
  mounted(){
    window.location.hash = "10.25";
    this.floatlabels = new FloatLabels( 'form.form-floating-labels', { exclude: '.no-floating-label' });
    this.username = this.multiple_accounts ? '' : this.saved_email;
  },
  created(){
    this.$nextTick(()=>{
      this.$refs.username_field.focus();
    });
  },
  computed: {
    text_username_address(){
      const textValue = this.getQuestionValue('user_name')
      return textValue ? textValue : "Username";
    },
    text_password_address(){
      const textValue = this.getQuestionValue('password')
      return textValue ? textValue : "Password";
    },
    logo(){
      return this.$store.getters.eatology ? '/2018_global/imgD/eatology-logo.png' : '/2018_global/imgD/logo_ns_white.png';
    },
    saved_email(){
      return this.$store.state.saved_email;
    },
    multiple_accounts(){
      return this.$store.state.multiple_accounts;
    },
    next_button_content(){
      return this.loading
          ? `<img src="${VUE_APP_LMS}/v4/imgD/spinner-small-white.svg" style="height: 1rem;">`
          : this.$t('body:button_next', 'Next');
    },
    existing_account_content(){
        return this.$t('body:existing_account', 'We already have an account associated with :saved_email . Log in below to enroll with this account', {
            replace: {
                ':saved_email': `<strong>${this.saved_email}</strong>`,
            },
        });
    },
  },
  methods: {

    gotoCreateAccount(){
      this.$store.commit('update_use_email_as_username',false);
      this.changeStep(10.1);
    },
    checkCaps(e){
      this.caps_lock_enabled = e.getModifierState && e.getModifierState( 'CapsLock' );
      if (this.caps_lock_enabled){
        console.log("CAPS is enabled");
      }
    },
    togglePassword(){
      this.show_password = !this.show_password;
    },
    fieldUpdated(field){
      this.validation[field] = true;
    },
    validateForm(){
      let ret = true;
      if (this.username==''){
        this.validation.username = false;
        ret = false;
      }
      if (this.password==''){
        this.validation.password = false;
        ret = false;
      }
      return ret;
    },
    async signIn(){
      if (this.loading) return;
      if (!this.validateForm()) return;
      this.loading = true;
      try{
        const loginResponse = await this.$store.dispatch('login', {
          user_name: this.username,
          password: this.password
        });
        this.loading = false;
        if (loginResponse=='unauthorized'){
          this.unauthorized_error = true;
          this.validation.password = false;
        }else{
          // initialize launchDarkly
          const userId = this.$store.state.user_id;
          await this.$store.dispatch('initializeLaunchDarkly', userId);
        }
      }catch (error) {
        console.error('Login error');
      }
      this.loading = false;
    },
    changeStep(val){
      this.$store.commit('change_step',val);
    }
  }
}
</script>

<style type="text/css" scoped>
  .slide-enter-active, .slide-leave-active {
    transition: all .2s ease;
  }
  .slide-enter, .slide-leave-to{
    transform: translatey(-10px);
    opacity: 0;
  }
</style>
