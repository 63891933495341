<template>
    <div>
        <div class="d-flex justify-content-center card-intro text-center">
            <div class="" style="width: 100%; max-width: 470px;">
                <h1 class="text-32 text-center" data-contentful="body:title">{{title}}</h1>
                <p v-html="text_above_questions" data-contentful="body:title_above"></p>
                <div class="alert alert-warning" role="alert" v-if="errorMessage!=''" style="font-size:0.9rem;">
                  <p v-html="errorMessage" data-contentful="body:error_message"></p>
                </div>
            </div>
        </div>

        <form action="" class="form-floating-labels fl-form" @submit.stop.prevent="submitValues">
            <div class="d-flex justify-content-center card-form">
                <div style="width: 100%; max-width: 300px;" v-if="show_questions">
                    <div>
                        <div v-for="(question,index) in questions" :key="index">
                            <custom-text v-if="question.type=='text'" :question="question" @inputChanged="inputChanged"></custom-text>
                            <custom-text v-if="question.type=='date'" :question="question" @inputChanged="inputChanged"></custom-text>
                            <custom-checkbox v-if="question.type=='checkbox'" :question="question" @inputChanged="inputChanged"></custom-checkbox>
                            <custom-select v-if="question.type=='select'" :question="question" @inputChanged="inputChanged"></custom-select>
                            <custom-radio v-if="question.type=='radio'" :question="question" @inputChanged="inputChanged"></custom-radio>
                            <custom-hidden v-if="question.type=='hidden'" :question="question" @inputChanged="inputChanged"></custom-hidden>
                            <custom-credit-card v-if="question.type=='CreditCard'" :question="question" @creditCardProcessed="submitValues" @creditCardError="loading=false" :ccProcessingData="ccProcessingData"></custom-credit-card>
                            <p class="extra-label" v-if="question.type=='extra_label'" v-html="question.label"></p>
                        </div>
                    </div>
                    <div class="row justify-content-center text-center mt-3 mb-3" v-if="text_below_questions!=''">
                        <div class="col-md-12 col-lg-12">
                          <p v-html="text_below_questions" data-contentful="body:title_below"></p>
                        </div>
                    </div>
                    <p class="mt-4">
                        <button
                            type="submit"
                            class="btn btn-primary-light shadow btn-block"
                            :disabled="loading || !enabled"
                            v-html="next_button_content"
                            data-contentful="body:button_next"
                        />
                    </p>
                    <p class="mt-4" v-if="!is_first_step">
                        <button
                            type="button"
                            class="btn btn-block btn-no-shadow text-uppercase text-spacing-4 btn-white"
                            @click="previousStep"
                            :disabled="loading_previous"
                            v-html="previous_button_content"
                            data-contentful="body:button_previous"
                        >
                            {{ $t('body:button_previous', 'Previous') }}
                        </button>
                    </p>
                    <p class="mt-4" v-if="is_insurance_step">
                        <button
                            type="button"
                            class="btn btn-block btn-no-shadow text-uppercase text-spacing-4 btn-no-shadow"
                            @click="openInsuranceInfoModal"
                            :disabled="loading || !enabled"
                            v-html="skip_button_content"
                            data-contentful="body:ask_me_later"
                        />
                    </p>
                </div>
            </div>
        </form>
        <AddressVerificationModal
            @confirmed="addressVerified"
        />
        <InsuranceInfoModal/>
    </div>
</template>

<script type="text/javascript">
    import mixins from '@/mixins';
    import CustomCheckbox from '@/components/form-fields/CustomCheckbox.vue';
    import CustomRadio from '@/components/form-fields/CustomRadio.vue';
    import CustomText from '@/components/form-fields/CustomText.vue';
    import CustomHidden from '@/components/form-fields/CustomHidden.vue';
    import CustomSelect from '@/components/form-fields/CustomSelect.vue';
    import CustomCreditCard from '@/components/form-fields/CustomCreditCard.vue';
    import AddressVerificationModal from '@/components/modals/AddressVerificationModal.vue';
    import InsuranceInfoModal from "@/components/modals/InsuranceInfoModal";
    import moment from 'moment';
    import axios from 'axios';
    import {sendEventToEventBridge, formatResponseFields, formatQuestionsArray} from "../helpers/aws-event-bridge";
    import { DATEPICKER_FOCUS_TIMEOUT } from '@/helpers/constants'
    export default {
        mixins: [mixins],
        data(){
            return {
                waiting: false,
                enabled: true,
                loading: false,
                loading_previous: false,
                floatlabels: null,
                show_throbber: false,
                show_questions: true,
                addressConfirmed: false,
                isSkipInsuranceStep: false
            }
        },
        components: {
            CustomCheckbox,
            CustomRadio,
            CustomText,
            CustomHidden,
            CustomSelect,
            CustomCreditCard,
            AddressVerificationModal,
            InsuranceInfoModal
        },
        mounted(){
            this.floatlabels = new FloatLabels( 'form.form-floating-labels', { exclude: '.no-floating-label' });

            const userId = this.$store.state.user_id;
            this.$store.dispatch('initializeLaunchDarkly', userId).then(() => this.prepareForm());

            window.location.hash = this.$store.state.step;
            $('#modal_insurance_info').on('show.bs.modal', () => {
                this.isSkipInsuranceStep = true;
            });
            $('#modal_insurance_info').on('hide.bs.modal', () => {
                this.submitValues();
                this.isSkipInsuranceStep = false;
            });
        },
        created(){
            this.$eventHub.$on('updateLoadingQuestion', value => {
                this.loading = value;
            });
            this.$eventHub.$on('startWaiting',()=>{
                this.startWaiting();
            });
            this.$eventHub.$on('stopWaiting',()=>{
                this.stopWaiting();
            });
        },
        watch: {
            text_above_questions: function() {
                this.blurAndFocusFirstQuestion();
            },
            questions: function() {
                this.blurAndFocusFirstQuestion();
            },
            locale: function() {
                this.prepareForm();
            },
        },
        computed: {
            locale() {
                return this.$store.state.locale;
            },
            streetNumberExists(){
                let address = this.questions.find( question => question.name === 'ship_address1');
                if (!address.value || address.value.length === 0){
                    return false;
                }
                if (!/\d+/.test(address.value)){
                    return false
                }
                return true;
            },
            ccProcessingData(){
              return {
                apiLoginID: this.questions.find( question => question.name === "Authorize_Net_apiLoginID").value,
                clientKey: this.questions.find( question => question.name === "Authorize_Net_clientKey").value
              }
            },
            logo(){
                return this.$store.getters.eatology ? '/2018_global/imgD/eatology-logo.png' : '/2018_global/imgD/logo_ns_white.png';
            },
            is_first_step(){
                return this.$store.state.is_first_step
            },
            formWidth(){
                return {
                    'col-sm-10 col-md-6': this.first_question && (!this.first_question.width || this.first_question.width==6),
                    'col-sm-10 col-md-10': this.first_question && this.first_question.width==10
                };
            },
            first_question(){
                return this.questions.length ? this.questions[0] : null;
            },
            next_button_content(){
                return this.loading ? `<img src="${VUE_APP_LMS}/v4/imgD/spinner-small-white.svg" style="height: 1rem;">` : this.$t('body:button_next', 'Next');
            },
            skip_button_content(){
                return this.loading ? `<img src="${VUE_APP_LMS}/v4/imgD/spinner-small-white.svg" style="height: 1rem;">` : this.$t('body:ask_me_later', 'Ask me later');
            },
            is_insurance_step() {
                return this.$store.state.display.pageTitle == "Insurance" && this.$store.state.featureFlags['WON-2130-Add-Bypass-Insurance-Step-option'];
            },
            previous_button_content(){
                return this.loading_previous ? `<img src="${VUE_APP_LMS}/v4/imgD/spinner-small-blue.svg" style="height: 1rem;">` : this.$t('body:button_previous', 'Previous');
            },
            title(){
                const title = this.first_question ? this.first_question.pageTitle : "";
                return this.$t('body:title', title)
            },
            text_above_questions(){
                let text_above = '';
                if (this.first_question){
                    if (this.first_question.textAboveQuestionsBoldedString!=""){
                        text_above = this.first_question.textAboveQuestions.replace(this.first_question.textAboveQuestionsBoldedString, "<strong>" + this.first_question.textAboveQuestionsBoldedString + "</strong>");
                    }
                    text_above = this.first_question.textAboveQuestions;
                }
                return this.$t('body:title_above', text_above)
            },
            text_below_questions(){
                const title_below = this.first_question ? this.first_question.textBelowQuestions : "";
                return this.$t('body:title_below', title_below)
            },
            questions(){
                return this.$store.state.questions;
            },
            previous_step(){
                return this.$store.state.prevStep;
            },
            errorMessage() {
                return this.$store.state.errorMessage ? this.$t('body:error_message', this.$store.state.errorMessage) : '';
            },
            isEnabledStartSelectionForm() {
                return this.$store.state.featureFlags['WON-2873-updates-to-start-selection-form'];
            },
        },
        methods: {
            addressVerified(){
                this.addressConfirmed = true;
                this.submitValues();
            },
            verifyAddress(){

                let bodyFormData = new FormData();
                bodyFormData.append('address[line1]', this.questions.find( question => question.name === 'ship_address1').value);
                bodyFormData.append('address[line2]', this.questions.find( question => question.name === 'ship_address2').value);
                bodyFormData.append('address[city]', this.questions.find( question => question.name === 'ship_City').value);
                bodyFormData.append('address[provinceOrState]', this.questions.find( question => question.name === 'ship_State').value);
                bodyFormData.append('address[postalOrZip]', this.questions.find( question => question.name === 'ship_Zip').value);
                bodyFormData.append('address[country]', this.questions.find( question => question.name === 'ship_country').value);

                return axios.post("https://api.postgrid.com/v1/addver/verifications?includeDetails=true&geocode=true", bodyFormData, {
                    headers: {
                        "x-api-key": process.env.VUE_APP_POSTGRID_KEY,
                        "content-type": "multipart/form-data"
                    }
                }).then( r => {
                    if (r && r.data && r.data.data){
                        let address = r.data.data;
                        // console.log(address)
                        this.$eventHub.$emit('verifyAddress', address);
                    }
                    this.loading = false;
                }).catch(() => {
                    // there was an error with Postgrid. We can't stop enrollment so just continue
                    console.error("There was an error with the Postgrid request")
                    this.addressVerified();
                    this.loading = true;
                });
            },
            onlyAnswerYes(options){
                return options.endsWith('|Y');
            },
            parentAnswer(q){
              let parent = this.$store.state.questions.find(x => q.visible_if_question_name==x.name);
              if (parent && parent.value == 'No' ){
                return true
              }
              return false;
            },
            scrollTo(id){
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(id).offset().top
                }, 500);
            },
            validateValues(){
                let ret = true;
                this.questions.map(q => {
                    /****
                     * Validation of required fields start here
                     ****/
                    // Validation for required fields that have no parents
                    if (q.visible_if_question_name=="0" && q.required==="Y" && ((!q.value)||(q.value=="")||(q.value==[]))){
                        // console.error("There is a validation error with: "+q.label);
                        this.$eventHub.$emit('question_has_error',{
                            'name': q.name,
                            'step': q.step
                        });
                        if (ret) this.scrollTo("#question_"+q.name);
                        ret = false;
                    }

                    // Validation for required fields that have parents
                    if (q.visible_if_question_name!="0"){
                        let parent = this.$store.state.questions.find(q2 => q2.name==q.visible_if_question_name);
                        if (parent && parent.hasOwnProperty('value') && parent.value==q.visible_if_field_value){
                            if (q.required==="Y" && ((!q.value)||(q.value=="")||(q.value==[]))){
                                // console.error("There is a validation error with: "+q.label);
                                this.$eventHub.$emit('question_has_error',{
                                    'name': q.name,
                                    'step': q.step
                                });
                                if (ret) this.scrollTo("#question_"+q.name);
                                ret = false;
                            }
                        }
                    }

                    /****
                     * Validation for custom rules
                     ****/
                    let rules = q.validation_rule.split('|').map(x => x.split(':'));
                    let isdatepicker = rules.find(x => x[1]=="date_picker") && rules.find(x => x[1]=="date_picker").length>0;

                    // max rule
                    let max = rules.find(x => x[0]=="max");
                    if (max && !isdatepicker && parseFloat(q.value)>parseFloat(max.pop())){
                        this.$eventHub.$emit('question_has_error',{
                            'name': q.name,
                            'step': q.step,
                            'error': 'max'
                        });
                        if (ret) this.scrollTo("#question_"+q.name);
                        ret = false;
                    }

                    // min rule
                    let min = rules.find(x => x[0]=="min");
                    if (min && !isdatepicker && parseFloat(q.value)<parseFloat(min.pop())){
                        this.$eventHub.$emit('question_has_error',{
                            'name': q.name,
                            'step': q.step,
                            'error': 'min'
                        });
                        if (ret) this.scrollTo("#question_"+q.name);
                        ret = false;
                    }

                    // valid dates
                    if (q.validation_rule=='type:date'){
                      let mom = moment(q.value,'MM/DD/YYYY');
                        // Validation Rule for (DOB - Profile details) TRUE if q.value > 18 years
                        // WON-2841 Validation Rule for (Weight_Loss_Medications_date - Health conditions) TRUE if q.value is on step 30.110
                        if (mom.isValid() && mom.isBetween('1900-01-01',moment().subtract(18,'y')) || q.step === '30.110') {
                            // console.log('valid date');
                        } else {
                            this.$eventHub.$emit('question_has_error', {
                              'name': q.name,
                              'step': q.step
                            });
                            if (ret) this.scrollTo("#question_" + q.name);
                            ret = false;
                        }
                    }

                    // valid datepicker dates
                    if (isdatepicker){

                        let moment_val = moment(q.value,"MM/DD/YYYY",true);

                        if (!moment_val._isValid){
                            // console.error('triggered datepicker format validation error');
                            this.$eventHub.$emit('question_has_error',{
                                'name': q.name,
                                'step': q.step,
                                'error_message': "Please enter a date with MM/DD/YYYY format"
                            });
                            if (ret) this.scrollTo("#question_"+q.name);
                        }

                        let min = rules.find(x => x[0]=="min");
                        if (min) {
                            let moment_min = moment(min.pop());
                            if (!moment_val.isSameOrAfter(moment_min)) {
                                // console.error('triggered datepicker min validation error');
                                this.$eventHub.$emit('question_has_error',{
                                    'name': q.name,
                                    'step': q.step
                                });
                                if (ret) this.scrollTo("#question_"+q.name);
                                ret = false;
                            }
                        }

                        let max = rules.find(x => x[0]=="max");
                        if (max) {
                            let moment_max = moment(max.pop());
                            if (!moment_val.isSameOrBefore(moment_max)) {
                                // console.error('triggered datepicker max validation error');
                                this.$eventHub.$emit('question_has_error',{
                                    'name': q.name,
                                    'step': q.step
                                });
                                if (ret) this.scrollTo("#question_"+q.name);
                                ret = false;
                            }
                        }

                        // console.log( "min = " + moment_min.format() );
                        // console.log( "max = " + moment_max.format() );
                        // console.log( "val = " + moment_val.format() );
                    }

                    // valid phone number
                    if (q.validation_rule=='type:phone'){
                        // console.log('validating phone');
                        if (q.value.match(/\d+/g) && q.value.match(/\d+/g).reduce((a,c)=>a+c,'').length!=10){
                            this.$eventHub.$emit('question_has_error',{
                                'name': q.name,
                                'step': q.step
                            });
                            if (ret) this.scrollTo("#question_"+q.name);
                            ret = false;
                        }
                    }

                    // custom string validation (starts with)
                    let starts_with = rules.find(x => x[0]=="starts_with");
                    if (starts_with && !q.value.startsWith(starts_with.pop())){
                        this.$eventHub.$emit('question_has_error',{
                            'name': q.name,
                            'step': q.step
                        });
                        if (ret) this.scrollTo("#question_"+q.name);
                        ret = false;
                    }

                    // custom string validation (ends with)
                    let ends_with = rules.find(x => x[0]=="ends_with");
                    if (ends_with && !q.value.endsWith(ends_with.pop())){
                        this.$eventHub.$emit('question_has_error',{
                            'name': q.name,
                            'step': q.step
                        });
                        if (ret) this.scrollTo("#question_"+q.name);
                        ret = false;
                    }

                    // custom string validation (char_count)
                    let char_count = rules.find(x => x[0]==="char_count");
                    if (char_count && q.value.length!==char_count.pop()){
                        this.$eventHub.$emit('question_has_error',{
                            'name': q.name,
                            'step': q.step
                        });
                        if (ret) this.scrollTo("#question_"+q.name);
                        ret = false;
                    }

                    // valid street number
                    if (q.name === 'ship_address1'){
                        if (!this.streetNumberExists){
                            this.$eventHub.$emit('question_has_error',{
                                'name': q.name,
                                'step': q.step
                            });
                            q.validation_message = 'Please enter your house or PO Box number';
                            if (ret) this.scrollTo("#question_"+q.name);
                            ret = false;
                        }
                    }

                    return q;
                });

                return ret;
            },
            submitValues(){
                this.loading = true;

                if (this.waiting) {
                    return false;
                }

                if (    this.questions.filter( question => question.type === 'CreditCard').length &&
                        this.questions.find( question => question.name === 'anetValue').value === '' &&
                        this.questions.find( question => question.name === 'anetDescriptor').value === ''
                ) {
                    this.$eventHub.$emit('submitCreditCardData');
                    return;
                }

                if (!this.isSkipInsuranceStep && !this.validateValues()) {
                    // console.log('validation failed');
                    this.loading = false;
                    return;
                }

                if ( (this.questions.filter( question => question.name === 'ship_address1').length > 0) && !this.addressConfirmed )
                {
                    this.verifyAddress();
                    return;
                }

                // console.log('Answers validated');
                let payload = { locale: this.$store.state.locale };
                let temp = this.$store.state.questions.map(q => {
                    // Need to map checkbox answers true to 'Y' and false to 'N'
                    if ((q.type==="checkbox") && ((q.value===true)||((q.value==='Y' && !this.parentAnswer(q)))) && this.onlyAnswerYes(q.options)){
                        payload[q.name] = "Y";
                    } else if ((q.type==="checkbox") && ((q.value==='N')||(q.value===false)) && this.onlyAnswerYes(q.options)){
                        payload[q.name] = "N";
                    } else {
                      if (this.parentAnswer(q)) {
                        payload[q.name] = '';
                      } else if ((q.value !== "") || (q.type == 'hidden')) {
                        payload[q.name] = q.value || '';
                      }
                    }

                });

                // console.log('Mapped questions');
                // check if we need to show the loading icon on top of the whole form
                // (only when checking insurance at the moment)
                if (this.title === "Insurance" || this.$store.state.display.pageTitle === "Insurance") {
                    this.show_throbber = true;
                }

                if(this.isSkipInsuranceStep) {
                    payload = { isSkipInsuranceStep: true, ...payload }
                }
                this.$store.dispatch('submitData', payload).then(r => {
                    /**
                     * JG 2018-11-06
                     * This is absolutely necessary! We need to destroy floating labels instances, empty the array questions
                     * and wait for vue to clean the inputs. After the next tick, we update the store with the new questions
                     * and wait for vue again to update and build the new fields/questions. After the new questions are loaded
                     * is when we can initialize the floating labels instances for the new set of questions.
                     * All this hassle is due to the fact that floating-labels.min.js creates html that replaces the input fields
                     * on create/destroy and these changes are not listened/seen by Vue.
                     * The same behavior applies when moving to the previous step.
                     */
                    this.floatlabels.destroy();
                    this.$store.commit('empty_questions');
                    this.show_questions = false;
                    this.$nextTick(()=>{
                        this.show_questions = true;
                        window.location.hash = r.step;
                        this.$store.commit('update_store',r);
                        this.$nextTick(()=>{
                            this.floatlabels = new FloatLabels( 'form.form-floating-labels', { exclude: '.no-floating-label' });
                            this.$eventHub.$emit('reset_fields');
                            this.loading = false;
                            this.show_throbber = false;
                            this.prepareForm();
                        });
                    });
                    // WON-2121 send enrollment event
                    if(r.success === true && r.jwt_token){
                        const requiredFields = formatResponseFields(r);
                        const questions = formatQuestionsArray(payload);
                        const eventDetails = {
                            ...requiredFields,
                            step: payload.step,
                            questions: questions
                        };
                        sendEventToEventBridge('enrollment-step', eventDetails, r.jwt_token);
                    }

                }).catch(e => {
                    if ( e && e.startsWith('Error: ') ) {
                        let question_name = e.replace('Error: ','').trim()
                        let q = this.questions.find( x => x.name === question_name )
                        this.$eventHub.$emit('question_has_error',{
                            'name': q.name,
                            'step': q.step
                        })
                    }
                    this.loading = false;
                    this.show_throbber = false;
                }).finally( () => {
                    this.addressConfirmed = false;
                });

                return false;
            },
            previousStep(){
                this.loading_previous = true;
                this.$store.dispatch('goto', { step: this.previous_step, locale: this.$store.state.locale }).then( r => {
                    this.floatlabels.destroy();
                    this.$store.commit('empty_questions');
                    this.show_questions = false;
                    this.$nextTick(()=>{
                        this.show_questions = true;
                        window.location.hash = r.step;
                        this.$store.commit('update_store', r);
                        this.$nextTick(()=>{
                            this.floatlabels = new FloatLabels( 'form.form-floating-labels', { exclude: '.no-floating-label' });
                            this.$eventHub.$emit('reset_fields');
                            this.loading_previous = false;
                            this.prepareForm();
                        });
                    });
                }).catch(e => {
                    this.loading_previous = false;
                });
            },
            openInsuranceInfoModal(){
                jQuery('#modal_insurance_info').modal('toggle');
            },
            startWaiting(){
                this.waiting = true;
            },
            stopWaiting(){
                this.waiting = false;
                this.enabled = true;
                this.submitValues();
            },
            inputChanged(question){
                this.$store.commit('update_question',question);
                this.$eventHub.$emit('question_updated',question);
            },
            defaultDateCalendar(min) {
              if (this.isEnabledStartSelectionForm) {
                return moment(min).add(1, 'days').format('MM/DD/YYYY');
              }
              return moment(min).format('MM/DD/YYYY');
            },

            installDatepicker() {
                this.questions.filter(q => q.validation_rule.includes('type:date_picker')).forEach(q => {
                    const rules = q.validation_rule.split('|');
                    let min = rules.filter(rule => rule.includes("min:")).reduce((acum,rule) => {
                        let x = rule.split(":");
                        return x[1];
                    }, '');
                    let max = rules.filter(rule => rule.includes("max:")).reduce((acum,rule) => {
                        let x = rule.split(":");
                        return x[1];
                    }, '');
                    let set_date = q.value;
                    if (min!=""){
                        set_date = this.defaultDateCalendar(min);
                        min = moment(min).format('MM/DD/YYYY');
                    } else {
                        set_date = moment().format('MM/DD/YYYY');
                    }
                    if (max!=""){
                        max = moment(max).format('MM/DD/YYYY');
                    }
                    this.$eventHub.$emit('update_value',{
                        name: q.name,
                        value: set_date
                    });

                    const isEnabled = this.isEnabledStartSelectionForm;

                    $('#question_' + q.name + ' input').datepicker("destroy");
                    $('#question_'+q.name+' input').datepicker({
                        orientation: "bottom left",
                        startDate: min ? min : '',
                        endDate: max ? max : '',
                        setDate: set_date,
                        format: "mm/dd/yyyy",
                        language: this.enableSpanishLocalization() ? this.locale.slice(0, 2): '',
                        autoclose: true,
                        beforeShowDay: function (date) {
                            if (isEnabled &&
                                moment(date).format('MM/DD/YYYY') === moment(min).add(2, 'days').format('MM/DD/YYYY')) {
                                return {
                                    enabled: true,
                                    classes: 'next-active-day'
                                };
                            }
                            return {
                                enabled: true,
                                classes: ''
                            };
                        },
                    }).on('changeDate', (e) => {
                        q.value = e.target.value;
                        this.$eventHub.$emit('question_updated',q);
                    });
                })

            },
            getFirstQuestion() {
                const q = this.questions[0];
                let input;
                if (q) {
                    const question_element = document.getElementById("question_"+q.name); // gets the question wrapper
                    if (question_element){ // if the first question is a valid question e.g. not an extra_label
                        input = question_element.getElementsByTagName("input")[0]; // gets the first input in the question
                        if (!input) {
                            input = question_element.getElementsByTagName("select")[0];
                        }
                    }
                }
                return input;
            },
            focusFistQuestion() {
                if (this.getFirstQuestion()) {
                    this.getFirstQuestion().focus();
                }
            },
            blurFirstQuestion() {
                if (this.getFirstQuestion()) {
                    this.getFirstQuestion().blur();
                }
            },
            blurAndFocusFirstQuestion() {
                setTimeout(() => {
                    this.blurFirstQuestion();
                    this.focusFistQuestion();
                }, DATEPICKER_FOCUS_TIMEOUT)
            },
            prepareForm() {
                this.installDatepicker();
                // emit event for all questions
                this.$eventHub.$emit('questions_installed');
                // scroll to top
                if (this.$store.state.disable_scroll_to_top === false) {
                    this.scrollTo('#app');
                }
            }
        }
    }
</script>

<style type="text/css">
    .extra-label img{
        width: 100%;
    }
    td.next-active-day {
      color: #333 !important;
      background-color: #EBE4F5 !important;
      border-color: #EBE4F5 !important;
    }
    .datepicker table tr td.active.active,
    .datepicker table tr td.active.highlighted.active,
    .datepicker table tr td.active.highlighted:active,
    .datepicker table tr td.active:active {
      color: #FFFFFFFF !important;
      background-color: #764ACC !important;
      border-color: #764ACC !important;
    }
</style>
