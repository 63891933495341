import { getToken as retrieveToken } from "./service";

const errorMessage = 'Could not get Token';

export async function getToken({ commit }) {
    try {
        const { 
            data: { 
                success,
                access_token: accessToken,
            },
        } = await retrieveToken();

        if (success) {
            commit('setToken', accessToken);
        } else {
            throw new Error(errorMessage);
        }
    } catch (error) {
        commit('setError', error);
    }
}
