<template>
    <div id="devhelp" style="position:fixed; top:110px; right: 0; z-index:100;" class="bg-primary p-4 text-white bg-card-footer rounded-left">
        <div>
            <button class="btn btn-xs btn-secondary" @click.stop.prevent="submit" data-contentful="body:autofill">
                {{ $t('body:autofill', 'Autofill') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        submit(){
            this.$eventHub.$emit('help_me');
            setTimeout(() => {
                $("button[type=submit]").click();
            }, 50);
        }
    }
}
</script>