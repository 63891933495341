<template>
    <div>
        <div class="d-flex justify-content-center card-intro text-center">
            <div class="" style="width: 100%; max-width: 470px;">
                <h1 class="text-32 text-center" data-contentful="body:title">
                    {{ $t("body:title", "Let's go!") }}
                </h1>
                <p data-contentful="body:title_above">
                    {{ $t("body:title_above", "Agree to the terms below. Click submit and you're ready to start learning how to lose weight while eating the foods you love!") }}
                </p>
            </div>
        </div>

        <form action="" class="form-floating-labels fl-form" @submit.stop.prevent="submitValues">
            <div class="d-flex justify-content-center card-form">
                <div style="width: 100%; max-width: 300px;">
                    <div class="pb-4">
                        <div v-for="(question,index) in confirmation_questions" :key="index">
                            <custom-text v-if="question.type=='text'" :question="question" @inputChanged="inputChanged"></custom-text>
                            <custom-text v-if="question.type=='date'" :question="question" @inputChanged="inputChanged"></custom-text>
                            <custom-credit-card v-if="question.type=='CreditCard'" :question="question" @inputChanged="inputChanged"></custom-credit-card>
                            <custom-checkbox v-if="question.type=='checkbox'" :question="question" @inputChanged="inputChanged"></custom-checkbox>
                            <custom-select v-if="question.type=='select'" :question="question" @inputChanged="inputChanged"></custom-select>
                            <custom-radio v-if="question.type=='radio'" :question="question" @inputChanged="inputChanged"></custom-radio>
                            <p class="extra-label" v-if="question.type=='extra_label'" v-html="question.label"></p>
                        </div>
                    </div>
                    <p>
                        <button
                            type="submit"
                            class="btn btn-primary-light shadow btn-block"
                            :disabled="loading"
                            v-html="submit_btn_content"
                            data-contentful="body:button_complete"
                        />
                    </p>
                    <p class="mt-4">
                        <button
                            type="button"
                            class="btn btn-block btn-no-shadow text-uppercase text-spacing-4 btn-white"
                            @click="previousStep"
                            data-contentful="body:button_previous"
                        >
                            {{ $t('body:button_previous', 'Previous') }}
                        </button>
                    </p>
                </div>
            </div>
        </form>
    </div>
</template>

<script type="text/javascript">
    import mixins from '@/mixins';
    import CustomCheckbox from '@/components/form-fields/CustomCheckbox.vue';
    import CustomRadio from '@/components/form-fields/CustomRadio.vue';
    import CustomText from '@/components/form-fields/CustomText.vue';
    import CustomSelect from '@/components/form-fields/CustomSelect.vue';
    import CustomCreditCard from "./form-fields/CustomCreditCard";
    import {formatResponseFields, sendEventToEventBridge} from "../helpers/aws-event-bridge";
    import moment from 'moment';
    import { mapState } from "vuex";

    export default {
        mixins: [mixins],
        data(){
            return {
                floatlabels: null,
                loading: false,
            }
        },
        components: {
            CustomCreditCard,
            CustomCheckbox,
            CustomRadio,
            CustomText,
            CustomSelect,
        },
        mounted(){
            window.location.hash = this.step;
            this.floatlabels = new FloatLabels( 'form.form-floating-labels', { exclude: '.no-floating-label' });
            this.$eventHub.$emit('questions_installed');
        },
        computed: {
            submit_btn_content(){
                return this.loading ? `<img src="${VUE_APP_LMS}/v4/imgD/spinner-small-white.svg" style="height: 1rem;">` : this.$t('body:button_complete', 'Submit');
            },
            questions(){
                return this.$store.state.questions.filter(x => x.is_review == "Y");
            },
            confirmation_questions(){
                return this.$store.state.questions.filter(x => x.step=="40");
            },
            previous_step(){
                return this.$store.state.prevStep;
            },
            getEnrollmentCompleteFlag(){
                return this.$store.state.featureFlags['won-5644-send-enrollment-complete-event-from-backend'];
            },
            ...mapState(['step']),
        },
        methods: {
            questionId(q){
                return "question_"+q.name;
            },
            reviewStep(step){
                this.$store.commit('update_reviewing_step',step);
            },
            questions_step(step){
                return this.$store.state.questions.filter( x => x.is_review=="Y" && x.step.startsWith(step) && x.type!='extra_label' );
            },
            submitValues(){
                if (!this.validateValues()) {
                    return;
                }
                let payload = { locale: this.$store.state.locale };
                let temp = this.$store.state.questions.map(q => {
                    if (q.value!=""){
                        payload[q.name] = q.value;
                    }
                });
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('submitData', payload).then(r => {
                        // WON-5644 moved enrollment-Complete event call to the backend
                        if(!this.getEnrollmentCompleteFlag) {
                            // WON-2121 send enrollment event
                            if(r.success === true && r.jwt_token){
                                let eventDetails = formatResponseFields(r);
                                console.log('Sending complete event to aws');
                                eventDetails.submitted_date_time = moment(new Date()).format("YYYY-MM-DD");;
                                sendEventToEventBridge('enrollment-complete', eventDetails, r.jwt_token)
                            } else {
                                console.log('Skipped aws event bridge');
                                console.dir(r);
                            }
                        }
                        console.dir(r);
                        console.log('Success. Redirecting now...');
                        setTimeout( () => {
                            window.location.href = r.redirect_url
                        }, 1000);
                    }).catch(e => {
                        console.log(e);
                        this.loading = false;
                    });
                }
            },
            validateValues(){
                let ret = true;
                this.questions.map(q => {
                    // Validation for fields that have no parents
                    if (q.visible_if_question_name=="0" && q.required==="Y" && ((!q.value)||(q.value=="")||(q.value==[]))){
                        // console.error("There is a validation error with: "+q.label);
                        this.$eventHub.$emit('question_has_error',q.name);
                        console.error("There is a problem with question "+q.name);
                        ret = false;
                    }
                    // Validation for fields that have parents
                    if (q.visible_if_question_name!="0"){
                        let parent = this.$store.state.questions.find(q2 => q2.name==q.visible_if_question_name);
                        if (parent.value==q.visible_if_field_value){
                            if (q.required==="Y" && ((!q.value)||(q.value=="")||(q.value==[]))){
                                // console.error("There is a validation error with: "+q.label);
                                this.$eventHub.$emit('question_has_error',q.name);
                                if (ret) this.scrollTo("#question_"+q.name);
                                ret = false;
                            }
                        }
                    }
                    // if (!ret){ console.log('error with '+q.name); }
                    return q;
                });
                // validating questions with step==40 (confirmation questions)
                this.confirmation_questions.map(q => {
                    // Validation for fields that have no parents
                    if (q.visible_if_question_name=="0" && q.required==="Y" && ((!q.value)||(q.value=="")||(q.value==[]))){
                        // console.error("There is a validation error with: "+q.label);
                        this.$eventHub.$emit('question_has_error',{
                            'name': q.name,
                            'step': q.step
                        });
                        if (ret) this.scrollTo("#question_"+q.name);
                        // console.error("There is a problem with question "+q.name);
                        ret = false;
                    }
                    // Validation for fields that have parents
                    if (q.visible_if_question_name!="0"){
                        let parent = this.$store.state.questions.find(q2 => q2.name==q.visible_if_question_name);
                        if (parent.value==q.visible_if_field_value){
                            if (q.required==="Y" && ((!q.value)||(q.value=="")||(q.value==[]))){
                                // console.error("There is a validation error with: "+q.label);
                                this.$eventHub.$emit('question_has_error',{
                                    'name': q.name,
                                    'step': q.step
                                });
                                if (ret) this.scrollTo("#question_"+q.name);
                                ret = false;
                            }
                        }
                    }
                    return q;
                });
                return ret;
            },
            inputChanged(question){
                this.$store.commit('update_question',question);
                this.$eventHub.$emit('question_updated',question);
            },
            scrollTo(id){
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(id).offset().top
                }, 500);
            },
            previousStep(){
                const urlHash = this.getUrlHash();
                this.$store.dispatch('goto', { step: this.previous_step, locale: this.$store.state.locale, hash: urlHash }).then(r => {
                    this.floatlabels.destroy();
                    this.$store.commit('empty_questions');
                    this.$nextTick(()=>{
                        this.$store.commit('update_store',r);
                    });
                });
            },
            prepareForm(){
                // rebuild floating labels
                this.floatlabels.rebuild();
                // emit event for all questions
                this.$eventHub.$emit('questions_installed');
                // scroll to top
                this.scrollTo('#site_main');
                // Setting focus to first question
                let q = this.questions[0];
                if (q) {
                    let question_element = document.getElementById("question_"+q.name); // gets the question wrapper
                    if (question_element){ // if the first question is a valid question e.g. not an extra_label
                        let input = question_element.getElementsByTagName("input")[0]; // gets the first input in the question
                        if (input){
                            input.focus();
                        } else { // if there was not an input then it's probably a select
                            input = question_element.getElementsByTagName("select")[0];
                            if (input){
                                input.focus();
                            }
                        }
                    }
                }
            }
        }
    }
</script>