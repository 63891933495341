import { DEFAULT_LOCALE, SUPPORTED_LOCALE, LOCALE_MAP, COOKIE_EXPIRES } from '@/helpers/constants';
import Cookies from 'js-cookie'
export default {
    methods: {
        getUrlParam(param = ''){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get(param) || ''
        },
        getUrlHash(){
            return window.location.hash.substring(1) || ''
        },
        getDeviceLocale () {
            const deviceLocale = window.navigator.userLanguage || window.navigator.language;
            const languageLocale = deviceLocale.substring(0, 2)
            return LOCALE_MAP[languageLocale] || LOCALE_MAP[DEFAULT_LOCALE];
        },
        getLocaleCookie(){
            return Cookies.get('locale')
        },
        setLocaleCookie(locale){
            locale = this.isSupported(locale) ? locale : LOCALE_MAP[DEFAULT_LOCALE];
            Cookies.set('locale', locale, { expires: COOKIE_EXPIRES })
        },
        setLocaleZendesk(locale) {
            try {
                if (zE) {
                    zE('messenger:set', 'locale', locale);
                } else {
                    throw new Error('Zendesk Messenger or setLocale function not available.');
                }
            } catch (error) {
                console.log(error);
            }
        },
        isSupported(locale) {
            return SUPPORTED_LOCALE.includes(locale);
        },

        getLocaleValue(){
            return this.getUrlParam('locale') || this.getLocaleCookie() || this.getDeviceLocale();
        },
        getQuestionValue(identifier){
            const questions = this.$store.state.questions
            if (questions.length) {
                const question = questions.find(question => question.name === identifier)
                return question ? question.label : '';
            }
            return '';
        },
        enableSpanishLocalization () {
            return this.$store.state.featureFlags['enable-spanish-localization'];
        },
        enablePrePopulateEmailAddress () {
            return this.$store.state.featureFlags['enable-prepopulate-email-address'];
        },
    }
}
