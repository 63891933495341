import axios from 'axios';

const patchLocaleURL = '/endpointsV3/App_Post_Locale.html';

export async function patchLocale(token, locale) {
    try {
        return await axios.post(patchLocaleURL, {
            token,
            locale,
        });
    } catch (error) {
        throw error;
    }
};
