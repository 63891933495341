import { DEFAULT_LOCALE, LOCALE_MAP } from '@/helpers/constants';
export function showZendeskChatBot(locale = LOCALE_MAP[DEFAULT_LOCALE], timeout = false) {
  if (zE) {
    zE('messenger:set', 'locale', locale);
    zE('messenger', 'show');
    zE('messenger', 'close');

    if (timeout) {
      setTimeout(() => {
        zE('messenger', 'open');
      }, VUE_APP_CHATBOT_ZENDESK_TIMEOUT || 30000);
    }
  }
}

export function closeZendeskChatBot() {
  if (zE) {
    zE('messenger', 'close');
  }
}

export function hideZendeskChatBot() {
  if (zE) {
    zE('messenger', 'close');
    zE('messenger', 'hide');
  }
}