import mixins from '@/mixins';
import { SEP_CONTENT_CONTENTFUL_TYPE, CONTENTFUL_REQUEST_LIMIT } from '@/helpers/constants';

export default {
    mixins: [mixins],
    methods: {
        async getContentfulEntries(locale) {
            const currentLocale = locale || this.$store.state.locale
            try {
                const { items } = await this.$contentful.getEntries({
                    locale: currentLocale,
                    content_type: SEP_CONTENT_CONTENTFUL_TYPE,
                    limit: CONTENTFUL_REQUEST_LIMIT
                });

                const entries = items.reduce((acc, entry) => {
                    const questionIdentifier = entry.fields.identifier.toLowerCase();
                    if (questionIdentifier) {
                        acc[questionIdentifier] = {
                            label: entry.fields.label || entry.fields.longLabel,
                        };
                    }
                    return acc;
                }, {});
                this.$store.commit('update_contentful_content', { entries, error: false })
            } catch (err) {
                this.$store.commit('update_contentful_content', { entries: {}, error: true })
                console.log('error getting contentful entries', err.message)
            }
        },
    },
}