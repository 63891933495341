<template>
    <div
            :class="['card', 'd-flex', 'flex-row', 'align-items-center', 'bg-purple-light', 'border-10', cardClass]"
            :style="cardStyle"
    >
        <div :class="['text-content', 'text-left', 'text-white','ml-3', 'pt-1', contentClass]">
            <h4 :class="['mb-1', titleClass]" data-contentful="banner:big_welcome_kit:title">
                {{ $t('banner:big_welcome_kit:title', 'Your free welcome kit') }}
            </h4>
            <p :class="['mb-1', descriptionClass]" data-contentful="banner:big_welcome_kit:description">
                {{ $t('banner:big_welcome_kit:description', `Once you finish signing up, you'll get a fun welcome kit
                from us in the mail that will help jumpstart your Wondr journey.`) }}
            </p>
        </div>
        <div>
            <img src="https://static-wondr.s3.amazonaws.com/icons/logo_card_shadow.png" alt="Welcome Kit"
                 :style="imageStyle"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isLargeScreen: false
            };
        },
        mounted() {
            this.checkScreenSize();
            window.addEventListener('resize', this.checkScreenSize);
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.checkScreenSize);
        },
        computed: {
            cardClass() {
                return {
                    'position-relative': this.isLargeScreen
                };
            },
            cardStyle() {
                return {
                    height: this.isLargeScreen ? '9.125rem' : '8rem',
                    marginLeft: this.isLargeScreen ? '-2.5rem' : '0',
                    width: this.isLargeScreen ? '23.4375rem' : '100%',
                    lineHeight: this.isLargeScreen ? '1.37rem' : '1.12rem'
                };
            },
            contentClass() {
                return {
                    'mr-4': this.isLargeScreen,
                    'mr-1': !this.isLargeScreen
                };
            },
            titleClass() {
                return {
                    'text-16': !this.isLargeScreen
                };
            },
            descriptionClass() {
                return {
                    'text-14': this.isLargeScreen,
                    'text-12': !this.isLargeScreen
                };
            },
            imageStyle() {
                return {
                    width: 'auto',
                    maxWidth: this.isLargeScreen ? '6.8rem' : '5.3rem',
                    objectFit: 'cover',
                    paddingTop: '0.9375rem',
                    marginRight: '5px',

                };
            }
        },
        methods: {
            checkScreenSize() {
                this.isLargeScreen = window.innerWidth >= 500;
            }
        }
    };
</script>