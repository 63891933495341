const DEFAULT_LOCALE = 'en';
const SUPPORTED_LOCALE = ['en-US', 'es-US'];
const SEP_CONTENT_CONTENTFUL_TYPE = 'sepContent';
const DATEPICKER_FOCUS_TIMEOUT = 100;
const CONTENTFUL_REQUEST_LIMIT = 1000;
const COOKIE_EXPIRES = 30;
const LOCALE_MAP = {
    'es': 'es-US',
    'en': 'en-US',
};

export {
    DEFAULT_LOCALE,
    SUPPORTED_LOCALE,
    SEP_CONTENT_CONTENTFUL_TYPE,
    DATEPICKER_FOCUS_TIMEOUT,
    CONTENTFUL_REQUEST_LIMIT,
    COOKIE_EXPIRES,
    LOCALE_MAP,
};