<template>
    <div class="form-group" :id="wrapper_question_id" :class="class_object_wrapper" v-if="this.show_question">
        <label :for="question_id" v-if="question.place_holder_text!='' && this.question.label!=''" v-html="question.label"/>
        <label :for="question_id" v-if="question.place_holder_text=='' && this.question.label!=''" v-html="question.label"/>
        <input
            v-if="!is_date && !is_phone"
            :pattern="pattern_object"
            :type="input_type"
            :step="step_val"
            :id="question_id"
            :class="class_object"
            class="form-control form-control-lg fl-input"
            :aria-describedby="aria_desc"
            @keydown="keyDown"
            @input="inputChanged"
            @blur="inputBlur"
            @focus="inputFocus"
            :value="question.value"
            ref="input"
            :placeholder="placeholder"
            :data-contentful="this.question.name"
        >
        <input
            v-if="is_date"
            :type="input_type"
            :step="step_val"
            :id="question_id"
            :class="class_object"
            class="form-control form-control-lg fl-input"
            :aria-describedby="aria_desc"
            @input="inputChanged"
            :value="question.value"
            ref="input"
            :placeholder="question.place_holder_text"
            v-mask="'##/##/####'"
            :data-contentful="this.question.name"
        >
        <input
            v-if="is_phone"
            :type="input_type"
            :step="step_val"
            :id="question_id"
            :class="class_object"
            class="form-control form-control-lg fl-input"
            :aria-describedby="aria_desc"
            @input="inputChanged"
            :value="question.value"
            ref="input"
            :placeholder="question.place_holder_text"
            v-mask="'(###) ###-####'"
            :data-contentful="this.question.name"
        >
        <span :id="aria_desc" class="form-text small" v-if="question.description">{{question.description}}</span>
        <div class="form-text small text-danger" v-if="has_error" v-html="custom_error || question.validation_message"></div>
        <div class="form-text small text-danger" v-if="eligibility_field_has_error" v-html="eligibility_field_error"></div>
        <div class="form-text small text-danger" v-if="confirmation_modal_error!=''" v-html="confirmation_modal_error"></div>
    </div>
</template>

<script type="text/javascript">
    import { mask } from 'vue-the-mask'
    export default {
        directives: { mask },
        props: ['question','hold'],
        data(){
            return {
                value: '',
                has_error: false,
                confirmation_modal_error: '',
                is_focus: false,
                custom_error: null,
                show_question: false
            }
        },
        created(){
            this.$eventHub.$on('help_me', () => {
                let value = "Test";
                if (this.question.name=="phone"){
                    value = "1231231231";
                } else if (this.question.name=="DOB"){
                    value = "11/11/1990";
                } else if (this.question.name=="ship_address1"){
                    value = "1234 Test";
                } else if (this.question.name=="weight"){
                    value = "200";
                } else if (this.question.name=="Join_Date"){
                    value = this.value;
                }
                this.value = value;
                this.question.value = value;
                this.$emit('inputChanged',this.question);
            });
            this.$eventHub.$on('confirmation_modal_error',({name,step,msg})=>{
                if ((this.question.name===name)&&(this.question.step===step)&&(msg!="")){
                    this.confirmation_modal_error = msg;
                    this.has_error = true;
                };
                if ((this.question.name===name)&&(this.question.step===step)&&(msg=="")){
                    this.confirmation_modal_error = '';
                    this.has_error = false;
                };
            });
            this.$eventHub.$on('question_has_error',({name,step,error_message})=>{
                if ((this.question.name===name)&&(this.question.step===step)){
                    this.has_error = true;
                    if (error_message){
                        this.custom_error = error_message;
                    }
                }
            });
            this.$eventHub.$on('reset_fields',()=>{
                if (this.hold && this.question.name=='password') {
                    this.question.hold_value = '********';
                    this.question.value = '********';
                    this.value = '********';
                } else {
                    this.value = this.question.value;
                    this.question.hold_value = this.question.value;
                }
                this.confirmation_modal_error = '';
                this.has_error = false;
                // Need to trigger fl-is-active class on input wrapper if the field has a value to trigger the floating label
                if (this.$refs.input){
                    if (this.question.value.length>0){
                        jQuery(this.$refs.input).parent().addClass("fl-is-active");
                    }
                }
            });
            this.$eventHub.$on('questions_installed', ()=>{
              if(this.parentAnswer(this.question)){
                this.question.value = "";
              }else {
                this.value = this.question.value;
              }
            });
            this.$eventHub.$on('update_value',(payload) => {
                if (this.question.name==payload.name){
                    this.value = payload.value;
                    if (this.$refs.input){
                        this.$refs.input.value = payload.value;
                    }
                }
                this.$store.commit('update_value', payload);
            });
            //WON 2841 Validation to show or hide Medical Start Date Question
            this.$eventHub.$on('question_updated', (q) => {
              if (this.question.visible_if_question_name == "Weight_Loss_Medications") {
                if ((q.name == this.question.visible_if_question_name) && (q.value == this.question.visible_if_field_value)) {
                  this.show_question = true;
                } else if ((q.name == this.question.visible_if_question_name) && (q.value != this.question.visible_if_field_value)) {
                  this.question.value = "";
                  this.show_question = false;
                }
              }
            });
        },
        mounted(){
            if (this.question.visible_if_question_name=="0" || this.show_this_question){
                this.show_question = true;
            }
        },
        computed: {
            placeholder(){
                return this.question.label!='' ? this.question.label : this.question.place_holder_text!='';
            },
            is_date(){
                return this.validation_rules.includes('type:date');
            },
            is_phone(){
                return this.validation_rules.includes('type:phone');
            },
            step_val(){
                if (this.validation_rules.includes('type:number')){
                    return '0.1';
                }
                return '';
            },
            pattern_object() {
                if (this.validation_rules.includes('type:number')) {
                    return '[0-9.]*'; // only for iOS keyboards
                }
            },
            input_type(){
                if ((this.validation_rules.includes('type:number'))||(this.validation_rules.includes('type:credit_card'))){
                    return 'number';
                }
                if (this.validation_rules.includes('type:email')){
                    return 'email';
                }
                if ((this.validation_rules.includes('type:phone'))||(this.validation_rules.includes('type:date'))){
                    return 'tel';
                }
                if (this.question.name=='password' && !this.is_focus){
                    return 'password';
                }
                return 'text';
            },
            wrapper_question_id(){
                return 'question_'+this.question.name;
            },
            question_id(){
                return "text_field_" + this.question.step + this.question.name;
            },
            aria_desc(){
                return "desc_text_" + this.question.step + this.question.name;
            },
            class_object(){
                return {
                    'is-invalid': this.has_error,
                    'is-active': this.value!='', // this makes the select input change to black text when autocomplete is triggered
                    'no-floating-label': this.question.place_holder_text!='' && this.question.label!=''
                }
            },
            class_object_wrapper(){
                return {
                    'fl-is-active': this.value!='', // this makes the select input change to black text when autocomplete is triggered
                }
            },
            validation_rules(){
                return this.question.validation_rule.split('|');
            },
            step(){
                return this.$store.state.step;
            },
            eligibility_field_has_error(){
                return this.question.name.startsWith('eligibility_field_') && this.has_error && this.question.value!=''
            },
            eligibility_field_error(){
                return "Sorry we couldn't find that " + this.question.label + " in our system.";
            },
            show_this_question(){
              let parent = this.$store.state.questions.find(x => this.question.visible_if_question_name == x.name);
              if (parent && parent.value == this.question.visible_if_field_value) {
                return true;
              }
              return false;
            },
        },
        methods: {
            inputBlur(){
                this.is_focus = false;
                this.$emit('inputBlur',this.question);
            },
            inputFocus(){
                this.is_focus = true;
                if (this.hold && this.question.name=='password' && this.value=='********') {
                    this.question.hold_value = '';
                    this.question.value = '';
                    this.value = '';
                }
            },
            keyDown(event){
                if ((this.question.name==='user_name' || (this.question.name==='user_email')) && event.key===" "){
                    event.preventDefault();
                }
            },
            inputChanged(event){
                let val = event.target.value;
                // If type=number only allow numbers and the dot symbol.
                // This is a hack to support type=number inputs on IE11
                if (this.validation_rules.includes('type:number')){
                    // console.log('pre: '+val);
                    val = val.split('').filter( x => (x>=0 && x<=9) || x=='.').join('');
                    this.$refs.input.value = val;
                    // console.log('post: '+val);
                }
                if ( !this.question.hold_value ) { // save original value in hold_value
                  this.question.hold_value = this.value;
                }
                this.value = val;
                this.has_error = false;
                this.question.value = this.value;
                this.$emit('inputChanged',this.question);
            },
            parentAnswer(q){
              let parent = this.$store.state.questions.find(x => q.visible_if_question_name==x.name);
              if (parent && parent.value == 'No' ){
                return true
              }
              return false;
            }
        }
    }
</script>
