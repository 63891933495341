<template>
    <div class="modal" tabindex="-1" role="dialog" id="popup_modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="extra-label" style="font-size:1rem;" v-html="body"></p>
                    <a class="btn btn-no-shadow text-uppercase btn-primary-light d-block mx-auto" style="color:white;" data-dismiss="modal">Ok</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        computed: {
            title(){
                return this.$t('popup:title', this.$store.state.popup_title);    
            },
            body(){
                return this.$t('popup:body', this.$store.state.popup_body);    
            },
        }
    }
</script>