import axios from 'axios';

const getTokenUrl = '/endpointsV3/App_Auth_Get_Token.html';

export async function getToken() {
    try {
        return await axios.post(getTokenUrl, {
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
        });
    } catch (error) {
        throw error;
    }
};
