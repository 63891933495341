<template>
    <div v-show="show_question">
        <div v-if="options.length==1" class="custom-control custom-checkbox custom-checkbox-lg mb-4 text-headline" :class="class_object" :id="wrapper_question_id" :data-contentful="question.name">
            <input type="checkbox" :id="question_id(0)" class="custom-control-input" v-model="value" @change="inputChanged">
            <label :for="question_id(0)" class="custom-control-label">
                <span v-html="question.label" class="text-weight-normal text-sans-serif"></span>
            </label>
        </div>
        <fieldset v-if="options.length>1" class="mb-4 text-headline" :id="wrapper_question_id">
            <legend>{{question.label}}</legend>
            <div v-for="(option,index) in options" :key="index" class="custom-control custom-checkbox" :class="class_object">
                <input type="checkbox" :id="question_id(index)" class="custom-control-input" :value="option.value" v-model="values" @change="inputChangedMultiple">
                <label :for="question_id(index)" class="custom-control-label" v-html="option.label"></label>
            </div>
        </fieldset>
        <span :id="aria_desc" class="form-text small" v-if="question.description">{{question.description}}</span>
        <div class="form-text small text-danger" v-if="has_error" v-html="question.validation_message"></div>
    </div>
</template>

<script type="text/javascript">
    export default {
        props: ['question','hold'],
        data(){
            return {
                value: null,
                values: [],
                has_error: false,
                show_question: false
            }
        },
        destroyed(){
            this.$eventHub.$off('reset_fields');
            this.$eventHub.$off('question_has_error');
            this.$eventHub.$off('question_updated');
            this.$eventHub.$off('questions_installed');
        },
        created(){
            this.$eventHub.$on('help_me', () => {
                let value = this.options[0].value;
                if (this.options.length>1){
                    this.values = [value];
                    this.question.value = [value];
                    this.$emit('inputChangedMultiple',this.question);
                } else {
                    this.value = value;
                    this.question.value = value;
                    this.$emit('inputChanged',this.question);
                }

            });
            this.$eventHub.$on('reset_fields',()=>{
                this.value = this.question.value;
                this.values = [];
            });
            this.$eventHub.$on('question_has_error',({name,step})=>{
                if ((this.question.name===name)&&(this.question.step===step)){
                    this.has_error = true;
                }
            });
            this.$eventHub.$on('question_updated',(q)=>{
                if ((this.question.visible_if_question_name!="") && (this.question.visible_if_field_value!="")){
                    if ((q.name==this.question.visible_if_question_name)&&(q.value==this.question.visible_if_field_value)){
                        this.show_question = true;
                    } else if ((q.name==this.question.visible_if_question_name)&&(q.value!=this.question.visible_if_field_value)) {
                        this.show_question = false;
                    }
                }
            });
            this.$eventHub.$on('questions_installed', () => {

                // question hasn't been initialized yet and there is a default value
                if ((this.value===null)||(this.value==='')){
                    if (this.question.default_value!=""){ 
                        this.value = this.question.default_value=='Y';
                        this.question.value = this.value;
                        this.$emit('inputChanged',this.question);
                    }
                } else {
                    this.value = this.question.value=='Y';
                }

                // initialize multiple choice checkboxes
                /*
                 * When we receive values from the server that are already stored they come as "N|N|Y|N|Y"
                 * so we need to parse that and map them through the options array since there is where
                 * the actual options and checkbox values are stored.
                 */
                if (this.question.value && (typeof this.question.value=='string') && this.question.value.split('|').length>1){
                    let values_received = this.question.value.split('|');
                    for (let i=0;i<values_received.length;i++){
                        if (values_received[i]=='Y'){
                            this.values.push(this.options[i].value);
                        }
                    }
                    // trigger change event so the parent is aware of the answers that were pre-selected
                    this.inputChangedMultiple();
                } else {
                    this.values = [];
                }

            });
        },
        mounted(){
            if (this.question.visible_if_question_name=="0" || this.show_this_question){
                this.show_question = true;
            }
        },
        computed: {
            show_this_question(){
                let parent = this.$store.state.questions.find(x => this.question.visible_if_question_name==x.name);
                if (parent){
                    if (parent.value==this.question.visible_if_field_value) return true;
                }
                return false;
            },
            wrapper_question_id(){
                return 'question_'+this.question.name;
            },
            options(){
                return this.question.options.split('\n').map(x => {
                    let temp = x.split('|');
                    return {
                        value: temp[1],
                        label: temp[0]                        
                    }
                });
            },
            class_object(){
                return {
                    'is-invalid': this.has_error
                }
            }
        },
        methods: {
            question_id(index){
                return "checkbox_"+this.question.name+"_"+index;
            },
            inputChanged(){
                this.has_error = false;
                if (this.hold) {
                    this.question.hold_value = this.value;
                } else {
                    this.question.value = this.value;
                }
                this.$emit('inputChanged',this.question);
            },
            inputChangedMultiple(){
                this.has_error = false;
                if (this.hold) {
                    this.question.hold_value = this.values;
                } else {
                    this.question.value = this.values;
                }
                this.$emit('inputChanged',this.question);
            }
        }
    }
</script>