<template>
    <div class="dropdown language-dropdown">
        <a
            href="#"
            class="dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-expanded="false"
        >
            <span class="dropdown-icon">
                <svg class="icon-20 icon-purple-dark"><use xlink:href="#world"/></svg>
            </span>
            <span class="dropdown-label text-purple-dark">
                {{options[selectedOption].shortLabel}}
            </span>
            <span class="dropdown-icon">
                <svg class="icon-16 icon-purple-dark"><use xlink:href="#chevrondown"/></svg>
            </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
            <button
                v-for="(option, index) in options"
                :key="option.key"
                :class="['dropdown-item', { active: index === selectedOption }]"
                @click="handleClick(index)"
                type="button"
            >
                <span class="dropdown-item-label">
                    {{option.label}}
                </span>
                <span v-if="index === selectedOption" class="dropdown-icon">
                    <svg class="icon-16 icon-purple"><use xlink:href="#checkmark"/></svg>
                </span>
            </button>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        props: {
            options: {
                type: Array,
                required: true
            },
            selectedValue: {
                type: Object
            },
            callback: {
              type: Function
            },
        },
        data() {
            const selectedOption = this.options.findIndex((item) => item.value === this.selectedValue.value);
            return {
                selectedOption,
            }
        },
        methods: {
            handleClick(index) {
                this.selectedOption = index;
                if (typeof this.callback === 'function') {
                    this.callback(this.options[index]);
                }
            }
        }
    }
</script>
