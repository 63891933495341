import { patchLocale } from "./service";

const errorMessage = 'Could not patch locale';

export async function setLocale({ commit, rootState }, locale) {
    try {
        const { data: { success } } = await patchLocale(rootState.auth.token, locale);

        if (success) {
            commit('update_locale', locale, { root: true });
        } else {
            throw new Error(errorMessage);
        }
    } catch (error) {
        commit('setError', error);
    }
}
