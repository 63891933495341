<template>
    <div></div>
</template>

<script>
    export default {
        props: ['question'],
        created() {
            this.$eventHub.$on('updateHiddenField', (data) => {
                if (this.question.name == data.name){
                    this.question.value = data.value;
                    this.$store.commit('update_value', this.question);
                }
            })
        }
    }
</script>