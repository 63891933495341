const config = {
    postGridKey: '{{VUE_APP_POSTGRID_KEY}}',
    lambdaAuthenticatorUrl: '{{VUE_APP_LAMBDA_AUTHENTICATOR}}',
    integrationServiceUrl: '{{VUE_APP_INTEGRATION_SERVICE_URL}}',
    launchDarklyClientId: '{{VUE_APP_CLIENT_SIDE_ID}}',
    contentfulSpaceId: '{{VUE_APP_CONTENTFUL_SPACE_ID}}',
    contentfulToken: '{{VUE_APP_CONTENTFUL_TOKEN}}',
    contentfulEnvironment: '{{VUE_APP_CONTENTFUL_ENVIRONMENT}}',
    clientId: '{{VUE_APP_CLIENT_ID}}',
    clientSecret: '{{VUE_APP_CLIENT_SECRET}}',
    environment: '{{VUE_APP_ENVIRONMENT}}',
};

export const postGridKey = readConfig(
    'postGridKey',
    'VUE_APP_POSTGRID_KEY',
    process.env.VUE_APP_POSTGRID_KEY
);

export const lambdaAuthenticatorUrl = readConfig(
    'lambdaAuthenticatorUrl',
    'VUE_APP_LAMBDA_AUTHENTICATOR',
    process.env.VUE_APP_LAMBDA_AUTHENTICATOR
);

export const integrationServiceUrl = readConfig(
    'integrationServiceUrl',
    'VUE_APP_INTEGRATION_SERVICE_URL',
    process.env.VUE_APP_INTEGRATION_SERVICE_URL
);

export const launchDarklyClientId = readConfig(
    'launchDarklyClientId',
    'VUE_APP_CLIENT_SIDE_ID',
    process.env.VUE_APP_CLIENT_SIDE_ID
);

export const contentfulSpaceId = readConfig(
    'contentfulSpaceId',
    'VUE_APP_CONTENTFUL_SPACE_ID',
    process.env.VUE_APP_CONTENTFUL_SPACE_ID
);

export const contentfulToken = readConfig(
    'contentfulToken',
    'VUE_APP_CONTENTFUL_TOKEN',
    process.env.VUE_APP_CONTENTFUL_TOKEN
);

export const contentfulEnvironment = readConfig(
    'contentfulEnvironment',
    'VUE_APP_CONTENTFUL_ENVIRONMENT',
    process.env.VUE_APP_CONTENTFUL_ENVIRONMENT
);

export const clientId = readConfig(
    'clientId',
    'VUE_APP_CLIENT_ID',
    process.env.VUE_APP_CLIENT_ID
);

export const clientSecret = readConfig(
    'clientSecret',
    'VUE_APP_CLIENT_SECRET',
    process.env.VUE_APP_CLIENT_SECRET
);

export const environment = readConfig(
    'environment',
    'VUE_APP_ENVIRONMENT',
    process.env.VUE_APP_ENVIRONMENT
);

function readConfig(key, envVarKey, envVarValue) {
    const configValue = config[key];
    if (configValue && configValue !== `{{${envVarKey}}}`) {
        return configValue;
    }
    return envVarValue;
}
