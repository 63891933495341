<template>
    <div>
        <div class="d-flex justify-content-center card-intro text-center">
            <div class="" style="width: 100%; max-width: 470px;">
                <h1 class="text-32 text-center" data-contentful="body:create_account">
                    {{ $t('body:create_account', 'Create your account') }}
                </h1>
            </div>
        </div>
        <form novalidate="novalidate" class="form-floating-labels" @submit.stop.prevent="submitForm">
            <div class="d-flex justify-content-center card-form">
                <div style="width: 100%; max-width: 300px;">
                    <div class="form-group centered-input" style="margin-bottom: 36px;">
                        <div>
                            <label for="username" class="text-gray-700" ref="username_label">{{ text_username_address }}</label>
                            <input
                                type="text"
                                id="username"
                                disabled
                                class="form-control form-control-lg text-center text-gray-700"
                                :placeholder='text_username_address'
                                :class="{ 'is-invalid' : !validation.username, 'fl-input': floatlabels !== null }"
                                aria-describedby="Username"
                                v-model='username'
                                tabindex="1"
                                ref="username_field"
                                data-contentful="user_name"
                            >
                            <span id="username_taken_description" class="form-text small text-danger" v-if="username_taken" data-contentful="body:username_taken">
                                {{ $t('body:username_taken', 'Sorry, this username is already taken.') }}
                            </span>
                        </div>
                    </div>
                    <div class="form-group centered-input" style="margin-bottom: 36px;">
                        <div>
                            <label for="password">{{ text_password_address }}</label>
                            <input
                                :type="show_password ? 'text' : 'password'"
                                id="password"
                                :placeholder="text_password_address"
                                @keyup="checkCaps"
                                @keydown="fieldUpdated('password')"
                                :class="{ 'is-invalid' : !validation.password, 'fl-input': floatlabels !== null }"
                                v-model="password"
                                tabindex="2"
                                ref="password_field"
                                class="form-control form-control-lg text-center"
                                autocomplete="off"
                                data-contentful="password"
                            >
                            <p class="mt-2 mb-0 small d-flex justify-content-between">
                                <button type="button" id="show_password" class="btn btn-xs btn-link p-0" aria-label="View Password" @click.stop.prevent="togglePassword">
                                    <svg class="icon-18 icon-gray-dark" v-if="show_password"><use xlink:href="#view"/></svg>
                                    <svg class="icon-18 icon-gray-dark" v-else><use xlink:href="#viewno"/></svg>
                                </button>
                            </p>
                        </div>
                        <div class="form-text small text-muted" v-if="caps_lock_enabled!=''">Caps lock is enabled</div>
                        <div class="custom-control custom-checkbox mt-1 mb-0" :class="{'is-valid':password_length_validation, 'is-invalid':!validation.password_length_validation}" style="font-size: .75rem;">
                            <input type="checkbox" id="check_2" class="custom-control-input" disabled :checked="password_length_validation">
                            <label for="check_2" class="custom-control-label text-gray-dark" data-contentful="body:password_validation_one">
                                {{ $t('body:password_validation_one', '8 or more characters') }}
                            </label>
                        </div>
                        <div class="custom-control custom-checkbox" :class="{'is-valid':password_symbol_validation, 'is-invalid':!validation.password_symbol_validation}" style="font-size: .75rem;">
                            <input type="checkbox" id="check_3" class="custom-control-input" disabled :checked="password_symbol_validation">
                            <label for="check_3" class="custom-control-label text-gray-dark" data-contentful="body:password_validation_two">
                                {{ $t('body:password_validation_two', 'At least 1 number or symbol ( !%^#@') }}
                            </label>
                        </div>
                    </div>

                    <p style="margin-top: 2rem;">
                        <button
                            type="submit"
                            class="btn btn-primary-light shadow btn-block"
                            :class="{'text-spacing-4':!loading}"
                            :disabled="loading"
                            v-html="next_button_content"
                            tabindex="3"
                            data-contentful="body:button_next"
                        >
                        </button>
                    </p>
                    <p>
                        <button
                            type="button"
                            class="btn btn-block btn-no-shadow text-uppercase text-spacing-4 btn-white"
                            @click="changeStep(10)"
                            data-contentful="body:button_previous"
                        >
                            {{ $t('body:button_previous', 'Previous') }}
                        </button>
                    </p>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import mixins from '@/mixins';
import email_suggestions from '@/helpers.js';
export default {
    mixins: [mixins],
    data(){
        return {
            loading: false,
            username: this.$store.state.use_email_as_username ? this.$store.state.saved_email : '',
            password: '',
            validation: {
                password: true,
                username: true,
                password_length_validation: true,
                password_symbol_validation: true,
            },
            floatlabels: null,
            username_taken: false,
            show_password: false,
            suggest: '',
            caps_lock_enabled: false,
        }
    },
    watch: {
        text_username_address:  function (value) {
            this.$refs.username_label.innerHTML = `<svg class="icon-14 icon-gray-700"><use xlink:href="#lock"/></svg> ${value}`;
        }
    },
    mounted(){
        window.location.hash = "10.20";
        this.floatlabels = new FloatLabels( 'form.form-floating-labels', { exclude: '.no-floating-label' });
    },
    created(){
        this.$nextTick(()=>{
            if (this.username!=''){
                this.$refs.password_field.focus();
            } else {
                this.$refs.username_field.focus();
            }
            this.$refs.username_label.innerHTML = `<svg class="icon-14 icon-gray-700"><use xlink:href="#lock"/></svg> ${this.text_username_address}`;
        });
    },
    computed: {
        text_username_address(){
            const textValue = this.getQuestionValue('user_name')
            return textValue ? textValue : "Username";
        },
        text_password_address(){
            const textValue = this.getQuestionValue('password')
            return textValue ? textValue : "Password";
        },
        logo(){
            return this.$store.getters.eatology ? '/2018_global/imgD/eatology-logo.png' : '/2018_global/imgD/logo_ns_white.png';
        },
        next_button_content(){
            return this.loading
                ? `<img src="${VUE_APP_LMS}/v4/imgD/spinner-small-white.svg" style="height: 1rem;">`
                : this.$t('body:button_next', 'Next');
        },
        password_length_validation(){
            return this.password.length>=8;
        },
        password_symbol_validation(){
            return this.password.search(/[\d\(\)\!%\^\#@]/g)!==-1;
        }
    },
    methods: {
        checkCaps(e){
            this.caps_lock_enabled = e.getModifierState && e.getModifierState( 'CapsLock' );
            if (this.caps_lock_enabled){
                console.log("CAPS is enabled");
            }
        },
        togglePassword(){
            this.show_password = !this.show_password;
        },
        checkUsername(){
            axios.post(this.$store.state.endpoints.check_username, {
                user_email: this.username
            }).then( r => {
                if (r.data && r.data==='Sorry that is not available.'){
                    this.username_taken = true;
                    this.validation.username = false;
                } else {
                    this.username_taken = false;
                }
            });
        },
        fieldUpdated(field,e){
            this.validation[field] = true;
            if (field=='username'){
                this.username_taken = false;
                if (e.key===' ') {
                    e.preventDefault();
                }
            }
            if (field=='password'){
                this.validation.password_length_validation = true;
                this.validation.password_symbol_validation = true;
            }
        },
        validateForm(){
            let ret = true;
            if ((this.username=='')||(this.username_taken)){
                this.validation.username = false;
                ret = false;
            }
            if (this.password==''){
                this.validation.password = false;
                ret = false;
            }
            if (!this.password_length_validation){
                this.validation.password = false;
                this.validation.password_length_validation = false;
                ret = false;
            }
            if (!this.password_symbol_validation){
                this.validation.password = false;
                this.validation.password_symbol_validation = false;
                ret = false;
            }
            return ret;
        },
        async submitForm(){
            if (!this.validateForm()) return;
            this.loading = true;
            try{
                const submitDataResponse = await this.submitUserData();
                window.location.hash = submitDataResponse.step;
                this.loading = false;
                this.$store.commit('update_store', submitDataResponse);

                const userId = this.$store.state.user_id;
                await this.$store.dispatch('initializeLaunchDarkly', userId);

                this.$store.dispatch('getToken');
            }catch (error) {
                this.loading = false;
                console.log(error);
            }
        },
        submitUserData() {
            const urlHash = this.getUrlHash();
            return this.$store.dispatch('submitData', {
                locale: this.$store.state.locale,
                hash: urlHash,
                user_email: this.$store.state.saved_email,
                user_name: this.username,
                password: this.password,
            });
        },
        changeStep(val){
            this.$store.commit('change_step',val);
        }
    }
}
</script>

<style type="text/css" scoped>
.slide-enter-active, .slide-leave-active {
    transition: all .2s ease;
}
.slide-enter, .slide-leave-to{
    transform: translatey(-10px);
    opacity: 0;
}
#email_suggestion{
    padding: 0.25rem 0.5rem;
    font-size: 0.9rem;
}
.next-btn{
    width: 191px;
}
.warning-input{
    border-color: #bf9a44;
}
.warning-label{
    color: #bf9a44 !important;
}
</style>
