<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="verify_address_modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-white shadow btn-close"></button>
                    <div class="text-center" style="width: 100%; max-width: 420px;">
                        <h2 class="modal-title mb-0 pb-0" data-contentful="modal:verify_address:title">
                            {{ $t('modal:verify_address:title', 'Confirm Address') }}
                        </h2>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-center">
                        <div style="width: 100%; max-width: 400px;" class="text-center">
                            <p class="mb-4" data-contentful="modal:verify_address:title_above">
                                {{ $t('modal:verify_address:title_above', 'Please verify your address.') }}
                            </p>
                            <div class="rounded-sm p-3 mt-1">
                                <div
                                    style="cursor: pointer;"
                                    class="border rounded py-3 px-4 shadow text-left"
                                    @click="selected = 'unverified'"
                                    :class="selected === 'unverified' ? 'border-primary-light' : 'border-gray-700'"
                                    :style="selected === 'unverified' ? 'border-width: 3px !important;' : ''"
                                >
                                    <strong data-contentful="modal:verify_address:original">
                                        {{ $t('modal:verify_address:original', 'Original:') }}
                                    </strong>
                                    <div v-html="$store.getters.formatted_address"></div>
                                </div>
                            </div>
                            <div class="rounded-sm p-3">
                                <div
                                    style="cursor: pointer;"
                                    class="border rounded py-3 px-4 shadow text-left"
                                    @click="selected = 'verified'"
                                    :class="selected === 'verified' ? 'border-primary-light' : 'border-gray-700'"
                                    :style="selected === 'verified' ? 'border-width: 3px !important;' : ''"
                                >
                                    <strong data-contentful="modal:verify_address:suggested">
                                        {{ $t('modal:verify_address:suggested', 'Suggested:') }}
                                    </strong>
                                    <div v-html="formatted_address"></div>
                                </div>
                            </div>
                            <div class="my-4">
                                <a
                                    data-dismiss="modal"
                                    class="btn btn-no-shadow text-uppercase text-spacing-4 btn-white mx-2"
                                    data-contentful="body:button_go_back"
                                >
                                    {{ $t('body:button_go_back', 'Go back') }}
                                </a>
                                <a
                                    @click.stop.prevent="confirm()"
                                    class="btn btn-no-shadow btn-primary-light text-uppercase mx-auto mx-2"
                                    :class="selected === null ? 'disabled' : ''"
                                    :disabled="selected === null"
                                    data-contentful="body:button_confirm"
                                >
                                    {{ $t('body:button_confirm', 'Confirm') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
export default {
    data(){
        return {
            address: {},
            selected: 'verified'
        }
    },
    created(){
        this.$eventHub.$on('verifyAddress', address => {
            this.address = address;
            jQuery('#verify_address_modal').modal('toggle');
        });
    },
    methods: {
        confirm(){
            // TODO send GTM event
            if (this.selected === 'verified') {
                // console.log(this.address)
                this.$eventHub.$emit('update_value', {'name': 'ship_address1', 'value': this.address.line1});
                this.$eventHub.$emit('update_value', {'name': 'ship_address2', 'value': this.address.line2 && this.address.line2.length ? this.address.line2 : '-' });
                this.$eventHub.$emit('update_value', {'name': 'ship_City', 'value': this.address.city});
                this.$eventHub.$emit('update_value', {'name': 'ship_State', 'value': this.address.provinceOrState});
                this.$eventHub.$emit('update_value', {'name': 'ship_Zip', 'value': this.address.postalOrZip});
                this.$eventHub.$emit('update_value', {'name': 'ship_country', 'value': this.address.country});
            }
            jQuery('#verify_address_modal').modal('hide');
            this.$emit('confirmed');
        },
    },
    computed: {
        formatted_address(){
            let address = '';
            address += this.address.line1;
            if (this.address.line2){
                address += " " + this.address.line2;
            }
            if (this.address.city){
                address += "<br/>" + this.address.city;
            }
            if (this.address.provinceOrState){
                address += ", " + this.address.provinceOrState;
            }
            if (this.address.postalOrZip){
                address += " " + this.address.postalOrZip;
            }
            return address;
        },
    }
}
</script>