<template>
    <div id="modal_corp_landing_waitlist" tabindex="-1" role="dialog" aria-label="Join the Waitlist" class="modal fade" aria-modal="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <h2 class="modal-title" data-contentful="modal:waitlist:title">
                        {{ $t('modal:waitlist:title', 'Join the Waitlist') }}
                    </h2>
                    <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-white shadow btn-close"></button>
                </div>
                <div class="modal-body">

                    <div class="d-flex justify-content-center mb-4 text-center">
                        <div style="width: 100%; max-width: 400px;">
                            <p data-contentful="modal:waitlist:title_above">
                                {{ $t('modal:waitlist:title_above', "We'll notify you when the next application period opens.") }}
                            </p>
                        </div>
                    </div>
                    <ValidationObserver v-slot="{ invalid, reset }" ref='observer'>
                    <form
                        method="post"
                        class="js-waitlist-form form-floating-labels"
                        novalidate=""
                        @submit.stop.prevent="submit"
                        @reset.prevent="reset"
                        v-show="!completed"
                    >
                        <div class="d-flex justify-content-center card-form">
                            <div style="width: 100%; max-width: 300px;">
                                <div class="form-group">
                                    <div>
                                        <label for="first_name">{{ text_first_name }}</label>
                                        <validation-provider rules="required" v-slot="{ errors }" class="form-text small text-danger" >
                                            <input
                                                type="text"
                                                id="first_name"
                                                name="first_name"
                                                class="form-control form-control-lg"
                                                :placeholder="text_first_name"
                                                :aria-label="text_first_name"
                                                :data-placeholder="text_first_name"
                                                v-model="first_name"
                                                data-contentful="modal:waitlist:first_name"
                                            >
                                            <span>{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div>
                                        <label for="last_name">{{ text_last_name }}</label>
                                        <validation-provider rules="required" v-slot="{ errors }" class="form-text small text-danger" >
                                            <input
                                                type="text"
                                                id="last_name"
                                                name="last_name"
                                                class="form-control form-control-lg"
                                                :placeholder="text_last_name"
                                                :aria-label="text_last_name"
                                                required=""
                                                :data-placeholder="text_last_name"
                                                v-model="last_name"
                                                data-contentful="modal:waitlist:last_name"
                                            >
                                            <span>{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div>
                                        <label for="user_email">{{ text_email_address }}</label>
                                        <validation-provider rules="required|email" v-slot="{ errors }" class="form-text small text-danger" >
                                            <input
                                                type="text"
                                                id="user_email"
                                                name="user_email"
                                                class="form-control form-control-lg"
                                                :placeholder="text_email_address"
                                                :aria-label="text_email_address"
                                                required=""
                                                :data-placeholder="text_email_address"
                                                v-model="user_email"
                                                data-contentful="modal:waitlist:email_address"
                                            >
                                            <span>{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>
                                <input type="hidden" name="C_Corporate_Clients_item_id" id="C_Corporate_Clients_item_id" value="' . (int)$corpClientRow['item_id'] . '">
                                <input type="hidden" name="code2" id="code2" value="' .$code2. '">
                                <p><button
                                    :disabled="invalid || loading"
                                    type="submit"
                                    class="btn btn-block shadow text-spacing-4 btn-primary-light"
                                    v-html="submit_button_content"
                                    data-contentful="body:button_join_waitlist"
                                /></p>
                                <p><button
                                    type="button"
                                    class="btn btn-block btn-no-shadow text-spacing-4 btn-white"
                                    data-dismiss="modal"
                                    data-contentful="body:button_cancel">
                                    {{ $t('body:button_cancel', 'Cancel') }}
                                </button></p>
                            </div>
                        </div>
                    </form>
                    </ValidationObserver>
                    <div v-show="completed">
                        <div class="d-flex justify-content-center card-form">
                            <div style="width: 100%; max-width: 300px;">
                                <p><button
                                    type="button"
                                    class="btn btn-block btn-no-shadow text-spacing-4 btn-white"
                                    data-dismiss="modal"
                                    data-contentful="body:button_dismiss"
                                >
                                    {{ $t('body:button_dismiss', 'Dismiss') }}
                                </button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import axios from 'axios';
export default {
    data(){
        return {
            loading: false,
            completed: false,
            first_name: '',
            last_name: '',
            user_email: '',
        }
    },
    components: {
        ValidationObserver
    },
    computed: {
        text_first_name(){
            return this.$t('modal:waitlist:first_name', 'First Name');
        },
        text_last_name(){
            return this.$t('modal:waitlist:last_name', 'Last Name');
        },
        text_email_address(){
            return this.$t('modal:waitlist:email_address', 'Email');
        },
        submit_button_content(){
            return this.loading
                ? `<img src="${VUE_APP_LMS}/v4/imgD/spinner-small-white.svg" style="height: 1rem;">`
                : this.$t('body:button_join_waitlist', 'Join the Waitlist');
        },
    },
    mounted(){
        $('#modal_corp_landing_waitlist').on('show.bs.modal', () => {
                new FloatLabels('form.form-floating-labels');
        });
        $('#modal_corp_landing_waitlist').on('hide.bs.modal', () => {
            this.completed = false;
            this.first_name = "";
            this.last_name = "";
            this.user_email = "";
        });
    },
    methods: {
        submit(){
            this.loading = true;
            axios.post('/ajax_save_Waitlist_Form.html', {
                first_name: this.first_name,
                last_name: this.last_name,
                user_email: this.user_email,
                C_Corporate_Clients_item_id: this.$store.state.corp_client_id
            }).then( () => {
                this.completed = true;
                this.$refs.observer.reset();
            }).finally( () => {
                this.loading = false;
            });
        }
    }
}
</script>