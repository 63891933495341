<template>
    <header role="banner" id="sep_header" class="">
        <div class="container-fluid">
            <div class="sep-header">
                <img :src="logo" alt="" class="img-fluid img-logo">
                <div v-show="enableSpanishLocalization()" class="right-side">
                    <locale-dropdown :options="localeOptions" :selectedValue="localeValue" :callback="handleOnSelect" />
                </div>
            </div>
            <div class="d-flex justify-content-center enrollment-progress-bar" >
                <div class="progress-parts" v-if="!app_error">
                    <div class="d-flex justify-content-between align-items-center">

                        <div class="progress-section-label section-label-1 btn btn-xs" :class="class_1_section">
                            <span class="sr-only">Account - Complete</span>
                            <span class="progress-section-label-text d-none d-sm-inline" aria-hidden="true" data-contentful="progress:account">
                                {{ $t('progress:account', 'Account') }}
                            </span>
                            <span class="progress-section-label-text d-inline d-sm-none" aria-hidden="true">1</span>
                            <span v-show="current_step>=20" class="progress-section-label-icon" aria-hidden="true">
                                <svg class="icon-16 icon-white"><use xlink:href="#checkmark"/></svg>
                            </span>
                        </div>

                        <div class="progress-section progress-section-1">
                            <div class="progress">
                                <div role="progressbar" :style="class_1_style" :aria-valuenow="class_1_aria" aria-valuemin="0" aria-valuemax="100" class="progress-bar"></div>
                            </div>
                        </div>

                        <div class="progress-section-label section-label-2 btn btn-xs" :class="class_2_section">
                            <span class="sr-only" aria-hidden="true">Profile - Current</span>
                            <span class="progress-section-label-text d-none d-sm-inline" aria-hidden="true" data-contentful="progress:profile">
                                {{ $t('progress:profile', 'Profile') }}
                            </span>
                            <span class="progress-section-label-text d-inline d-sm-none" aria-hidden="true">2</span>
                            <span v-if="current_step_is(2)" class="progress-section-label-steps">{{step_details}}</span>
                            <span v-show="current_step>=30" class="progress-section-label-icon" aria-hidden="true">
                                <svg class="icon-16 icon-white"><use xlink:href="#checkmark"/></svg>
                            </span>
                        </div>

                        <div class="progress-section progress-section-2">
                            <div class="progress">
                                <div role="progressbar" :style="class_2_style" :aria-valuenow="class_2_aria" aria-valuemin="0" aria-valuemax="100" class="progress-bar"></div>
                            </div>
                        </div>

                        <div class="progress-section-label section-label-3 btn btn-xs" :class="class_3_section">
                            <span class="sr-only" aria-hidden="true">Health and Lifestyle</span>
                            <span class="progress-section-label-text d-none d-sm-inline" aria-hidden="true" data-contentful="progress:health">
                                {{ $t('progress:health', 'Health &amp; Lifestyle') }}
                            </span>
                            <span class="progress-section-label-text d-inline d-sm-none" aria-hidden="true">3</span>
                            <span class="progress-section-label-steps" v-if="current_step_is(3)">{{step_details}}</span>
                            <span v-show="current_step>=40" class="progress-section-label-icon" aria-hidden="true">
                                <svg class="icon-16 icon-white"><use xlink:href="#checkmark"/></svg>
                            </span>
                        </div>

                        <div class="progress-section progress-section-3">
                            <div class="progress">
                                <div role="progressbar" :style="class_3_style" :aria-valuenow="class_3_aria" aria-valuemin="0" aria-valuemax="100" class="progress-bar"></div>
                            </div>
                        </div>

                        <div class="progress-section-label section-label-4 btn btn-xs" :class="class_4_section">
                            <span class="sr-only" aria-hidden="true">Agreements</span>
                            <span class="progress-section-label-text d-inline d-sm-none" aria-hidden="true">4</span>
                            <span class="progress-section-label-text d-none d-sm-inline" aria-hidden="true" data-contentful="progress:agreements">
                                {{ $t('progress:agreements', 'Agreements') }}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script type="text/javascript">
    import mixins from '@/mixins';
    import ContentfulMixin from '@/mixins/contentful';
    import LocaleDropdown from './LocaleDropdown';
    export default{
        mixins: [mixins, ContentfulMixin],
        components: {
            LocaleDropdown,
        },        
        props: ['app_error'],
        computed: {
            localeOptions() {
                return [
                    {
                        label: this.$t('toggle:english_label', 'English'),
                        shortLabel: this.$t('toggle:english_short_label', 'Eng'),
                        value: 'en-US',
                    },
                    {
                        label: this.$t('toggle:spanish_label', 'Spanish'),
                        shortLabel: this.$t('toggle:spanish_short_label', 'Spa'),
                        value: 'es-US'
                    },
                ]
            },
            localeValue(){
                const locale = this.$store.state.locale;
                return this.localeOptions.find((item) => item.value === locale);
            },
            current_step(){
                return parseFloat(this.$store.state.step);
            },
            this_step(){
                return this.$store.state.this_one_in_major_step;
            },
            total_steps(){
                return this.$store.state.num_req_in_major_step;
            },
            step_details(){
                return this.this_step + "/" + this.total_steps;
            },
            class_1_section(){
                return {
                    'btn-primary': this.current_step>=10,
                    'active': this.current_step<20,
                    'complete': this.current_step>=20,
                }
            },
            class_1_progress_width(){
                if (this.current_step==10){
                    return 0;
                } else if ((this.current_step==10.1)||(this.current_step==10.2)){
                    return 50;
                } else if (this.current_step>=20){
                    return 100;
                }
                return 0;
            },
            class_1_style(){
                return {
                    'width': this.class_1_progress_width+"%"
                }
            },
            class_1_aria(){
                return {
                    'width': this.class_1_progress_width
                }
            },
            class_2_section(){
                return {
                    'btn-primary': this.current_step>=20,
                    'active': this.current_step>=20 && this.current_step<30,
                    'complete': this.current_step>=30,
                    'btn-white': this.current_step<20
                }
            },
            class_2_progress_width(){
                if (this.current_step>=20 && this.current_step<30){
                    // return (this.current_step - 20)*100;
                    return (this.this_step/this.total_steps)*100;
                } else if (this.current_step>=30){
                    return 100;
                }
                return 0;
            },
            class_2_style(){
                return {
                    'width': this.class_2_progress_width + "%"
                }
            },
            class_2_aria(){
                return {
                    'width': this.class_2_progress_width
                }
            },
            class_3_section(){
                return {
                    'btn-primary': this.current_step>=30,
                    'active': this.current_step>=30 && this.current_step<40,
                    'complete': this.current_step>=40,
                    'btn-white': this.current_step<30
                }
            },
            class_3_progress_width(){
                if (this.current_step>=30 && this.current_step<40){
                    // return (this.current_step - 30)*100;
                    return (this.this_step/this.total_steps)*100;
                } else if (this.current_step>=40){
                    return 100;
                }
                return 0;
            },
            class_3_style(){
                return {
                    'width': this.class_3_progress_width + "%"
                }
            },
            class_3_aria(){
                return {
                    'width': this.class_3_progress_width
                }
            },
            class_4_section(){
                return {
                    'btn-primary': this.current_step>=40,
                    'active': this.current_step>=40,
                    'btn-white': this.current_step<40
                }
            },
            logo(){
                return `${VUE_APP_LMS}/v5.0_wondr/imgD/logo_header.png`;
            },
        },
        methods: {
            current_step_is(step){
                if (this.current_step>=0 && this.current_step<20 && step==1){
                    return true;
                }
                if (this.current_step>=20 && this.current_step<30 && step==2){
                    return true;
                }
                if (this.current_step>=30 && this.current_step<40 && step==3){
                    return true;
                }
                return false;
            },
            handleOnSelect(selectedItem) {
                const locale = selectedItem.value;
                this.setLocaleActions(locale);
                this.updateQuestionsStore();
            },
            setLocaleActions(locale) {
                this.setLocaleCookie(locale);
                this.setLocaleZendesk(locale);
                this.$store.commit('update_locale', locale);
                if (this.$store.state.auth.token) {
                    this.$store.dispatch('setLocale', locale);
                }
            },
            updateQuestionsStore() {
                const urlHash = this.getUrlHash();
                const step = this.current_step == 10.2 ? 10 : this.current_step;
                const initializeSteps = [10, 10.1];
                const action = initializeSteps.includes(this.current_step) ? 'initialize' : 'questions';
                this.$store.dispatch('updateQuestions', {
                    action,
                    step: initializeSteps.includes(this.current_step) ? undefined : step,
                    locale: this.$store.state.locale,
                    hash: urlHash,
                });
            },
        }
    }
</script>