<template>
    <fieldset v-show="show_question" :id="wrapper_question_id" :data-contentful="question.name" class="mb-4 text-headline">
        <legend v-if="question.label!=''" v-html="question.label"></legend>
        <div v-if="this.isEnabledLargeWelcomeKit && question.name === 'welcome_kit'">
            <WelcomeKitBanner/>
        </div>
        <div v-else>
            <div v-for="option in options" class="custom-control custom-radio" :class="class_object">
                <input type="radio" :id="option_id(option)" :value="option.value" v-model="value" @change="inputChanged"
                       class="custom-control-input">
                <label :for="option_id(option)" class="custom-control-label" :class="class_label_object(option.value === 'digital')" v-html="option.label"></label>
            </div>
        </div>
        <span :id="aria_desc" class="form-text small" v-if="question.description">{{question.description}}</span>
        <div class="form-text small text-danger" v-if="has_error" v-html="question.validation_message"></div>
    </fieldset>
</template>

<script type="text/javascript">
import WelcomeKitBanner from '@/components/WelcomeKitBanner.vue';
    export default {
        components: {
            WelcomeKitBanner
        },
        props: ['question','hold'],
        data(){
            return {
                value: '',
                has_error: false,
                show_question: false
            }
        },
        created(){
            this.$eventHub.$on('help_me', () => {
                let value = this.options.length > 1 ? this.options[1].value :  this.options[0].value;
                this.value = value;
                this.question.value = value;
                this.$emit('inputChanged',this.question);
            });
            this.$eventHub.$on('question_has_error',({name,step})=>{
                if ((this.question.name===name)&&(this.question.step===step)){
                    this.has_error = true;
                }
            });
            this.$eventHub.$on('reset_fields',()=>{
                this.value = this.question.value;
            });
            this.$eventHub.$on('question_updated',(q)=>{
                if ((this.question.visible_if_question_name!="") && (this.question.visible_if_field_value!="")){
                    if ((q.name==this.question.visible_if_question_name)&&(q.value==this.question.visible_if_field_value)){
                        this.show_question = true;
                    } else if ((q.name==this.question.visible_if_question_name)&&(q.value!=this.question.visible_if_field_value)) {
                      this.value = '';
                      this.question.value = '';
                      this.show_question = false;
                    }
                }
            });
            this.$eventHub.$on('questions_installed', () => {
              if (this.parentAnswer(this.question)) {
                this.value = '';
                this.question.value = '';
              } else {
                  this.value = this.question.value;
                  if (this.question.name === "welcome_kit" && !this.value) {
                      this.value = this.isEnabledLargeWelcomeKit ? 'physical' : 'digital';
                      this.question.value = this.value;
                  }
              }
            });
        },
        mounted(){
            if (this.question.visible_if_question_name=="0" || this.show_this_question){
                this.show_question = true;
            }
        },
        computed: {
            wrapper_question_id(){
                return 'question_'+this.question.name;
            },
            options(){
                return this.question.options.split('\n').map(x => {
                    let temp = x.split('|');
                    return {
                        value: temp[1],
                        label: temp[0]
                    }
                });
            },
            question_id(){
                return "text_field_" + this.question.name;
            },
            class_object(){
                return {
                    'is-invalid': this.has_error,
                    'custom-control-inline': this.question.presentation_format === 'radio_horizontal',
                    'welcome-kit': this.question.name==="welcome_kit"
                }
            },
            show_this_question(){
              let parent = this.$store.state.questions.find(x => this.question.visible_if_question_name == x.name);
              if (parent && parent.value == this.question.visible_if_field_value) {
                return true;
              }
              return false;
            },
            isEnabledLargeWelcomeKit(){
                return this.$store.state.featureFlags['enable-large-welcome-kit'];
            }
        },
        methods: {
            class_label_object(isDigital){
                return {
                    'leaf-icon' : this.question.name==="welcome_kit" && isDigital
                }
            },
            inputChanged(){
                this.has_error = false;
                if (this.hold) {
                    this.question.hold_value = this.value;
                } else {
                    this.question.value = this.value;
                }
                this.$emit('inputChanged',this.question);
            },
            option_id(option){
                return "option_" + this.question.name + "_" + option.value;
            },
            parentAnswer(q){
              let parent = this.$store.state.questions.find(x => q.visible_if_question_name==x.name);
              if (parent && parent.value == 'No' ){
                return true
              }
              return false;
            }
        }
    }
</script>
